function phraseSelectionAlert() {
    let alertDiv = document.getElementById('customAlert');
    if (!alertDiv) {
        alertDiv = document.createElement('div');
        alertDiv.id = 'customAlert';
        alertDiv.style.cssText = 'display: none; position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.5); z-index: 1000; display: flex; justify-content: center; align-items: center;';
        document.body.appendChild(alertDiv);

        alertDiv.innerHTML = `
            <div style="background-color: white; padding: 20px; border-radius: 5px; width: auto; max-width: 700px; display: flex; flex-direction: column;">
                <div style="margin-bottom: 20px;">
                    <p>Trying to translate a phrase? Drag over fewer than 70 characters (approx 10-14 words). Ensure you start from the first letter of the phrase, otherwise translation won't be triggered.</p>
                    <p>Want to translate a word? Just click on it once. To translate a whole sentence, first click on a word in the sentence.</p>
                </div>
                <div style="text-align: right;">
                    <button id="closeAlert" style="background-color: #00448F; color: white; border: none; padding: 10px; border-radius: 5px; cursor: pointer;">Close</button>
                </div>
            </div>
        `;

        document.getElementById('closeAlert').addEventListener('click', function() {
            alertDiv.style.display = 'none';
        });
    } else {
        alertDiv.style.display = 'flex';
    }
}

module.exports = phraseSelectionAlert;