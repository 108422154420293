import consumer from "../.././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const chatFeedbackEl = document.getElementById('js-chat-feedback-container');

  if (chatFeedbackEl) {
    let chatId = chatFeedbackEl.dataset.chatId;
    let completionTimeout;
    
    // Check if feedback is already generated
    const feedbackGeneratedEl = document.getElementById('js-chat-feedback-success');
    const isFeedbackGenerated = !feedbackGeneratedEl.classList.contains('d-none');

    if (!isFeedbackGenerated) {
      const subscription = consumer.subscriptions.create({ channel: "ChatFeedbackChannel", chat_id: chatId }, {
        connected() {
          // Set a 30-second timeout when the connection is established
          completionTimeout = setTimeout(() => {
            console.log("Feedback generation timed out. Refreshing page.");
            window.location.reload();
          }, 30000);
        },
        received(data) {
          if (data.completed) {
            // Clear the timeout as we've received the completion signal
            clearTimeout(completionTimeout);

            // Refresh the page
            console.log("Feedback generation completed. Refreshing page.");
            window.location.reload();
          }
        }
      });

      // Clean up function
      return () => {
        clearTimeout(completionTimeout);
        subscription.unsubscribe();
      };
    }
  }
});
