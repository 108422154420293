import AudioRecorder from 'audio-recorder-polyfill'

class PronunciationService {
  constructor(csrfToken, language, dialectCode) {
    this.language = language;
    this.dialectCode = dialectCode;
    this.csrfToken = csrfToken;

    this.isRecording = false;
    this.timer;
    this.mediaRecorder;
    this.audioChunks = [];
  }

  alreadyRecording() {
    return this.isRecording;
  }

  stopPronunciation(btn) {
    clearTimeout(this.timer);

    btn.innerHTML = '<i class="fa fa-microphone"></i> Say it';
    btn.classList.remove('pulse');

    this.isRecording = false;

    this.mediaRecorder.stop();
  }

  async startPronunciation(btn, text, suggestionIndex) {
    this.emitPronunciationStarted();
    this.isRecording = true;

    btn.innerHTML = '<i class="fa fa-stop"></i> Stop recording';
    btn.classList.add('pulse');
    this.setupTimer(btn, text, suggestionIndex)

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    await this.setupRecordBtn(btn, text, stream, suggestionIndex)

    this.mediaRecorder.start();

  }

  // setup 15 second timer that gets cancelled if user stops recording before 15 seconds
  setupTimer(btn, text, suggestionIndex) {
    const that = this;

    that.timer = setTimeout(function () {
      that.stopPronunciation(btn, text, suggestionIndex);
    }, 45000);
  }


  async setupRecordBtn(btn, text, stream, suggestionIndex) {
    const that = this;

    const audioConfig = {
      sampleRate: 16000,
      channelCount: 1,
      bitsPerSample: 16
    };

    that.mediaRecorder = new AudioRecorder(stream, audioConfig);

    that.mediaRecorder.addEventListener('dataavailable', e => {
      that.audioChunks.push(e.data);
    });

    that.mediaRecorder.addEventListener('stop', async e => {
      const audioBlob = new Blob(that.audioChunks, { type: "audio/wav" });

      // Resample the audio if needed
      const resampledBlob = await this.resampleAudio(audioBlob, 16000, 1);

      this.sendDataToRailsBackend(text, resampledBlob, suggestionIndex)

      that.audioChunks = [];
    });
  }

  async resampleAudio(audioBlob, targetSampleRate, targetChannels) {
    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const offlineContext = new OfflineAudioContext(
      targetChannels,
      audioBuffer.duration * targetSampleRate,
      targetSampleRate
    );

    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineContext.destination);
    source.start(0);

    const renderedBuffer = await offlineContext.startRendering();

    const wavBuffer = this.audioBufferToWav(renderedBuffer);
    return new Blob([wavBuffer], { type: 'audio/wav' });
  }

  audioBufferToWav(buffer) {
    const numChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    const bytesPerSample = bitDepth / 8;
    const blockAlign = numChannels * bytesPerSample;

    const bufferLength = buffer.length;
    const byteRate = sampleRate * blockAlign;
    const dataSize = bufferLength * blockAlign;

    const headerSize = 44;
    const wavBuffer = new ArrayBuffer(headerSize + dataSize);
    const view = new DataView(wavBuffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + dataSize, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, format, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, byteRate, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, 'data');
    view.setUint32(40, dataSize, true);

    const channelData = [];
    for (let i = 0; i < numChannels; i++) {
      channelData.push(buffer.getChannelData(i));
    }

    let offset = 44;
    for (let i = 0; i < bufferLength; i++) {
      for (let channel = 0; channel < numChannels; channel++) {
        const sample = channelData[channel][i];
        view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        offset += 2;
      }
    }

    return wavBuffer;
  }

  sendDataToRailsBackend(text, audioBlob, suggestionIndex) {
    const formData = new FormData();
    formData.append("audio", audioBlob, "recorded_audio.wav");
    formData.append("language", this.language);
    formData.append("dialect_code", this.dialectCode);
    formData.append("text", text);

    let suggestionDiv = document.getElementById(`js-suggestion-${suggestionIndex}`);

    if (suggestionDiv) {
      this.addChatLoader(suggestionDiv, "Analyzing pronunciation...");
    }

    fetch('/langua/text_pronunciation', {
      method: "POST",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      credentials: 'same-origin',
      body: formData
    }).then(response => {
      return response.json();
    }).then(data => {
      if (suggestionDiv) {
        this.removeChatLoader(suggestionDiv);

        // Extract scores
        const overallScore = data['score'][0];
        const wordScores = data['score'][1];

        // Determine the feedback message based on overall score
        let feedbackMessage = '';
        if (overallScore >= 87) {
          feedbackMessage = 'Excellent';
        } else if (overallScore >= 78) {
          feedbackMessage = 'Good pronunciation';
        } else {
          feedbackMessage = 'Not quite, listen & try again!';
        }

        let feedbackHTML = `<span class="mb-2"><strong>${feedbackMessage}</strong></span><br>`;

        // Split the text into words
        let words = text.split(' ');

        // Ensure we have the same number of words and scores
        if (words.length === wordScores.length) {
          words.forEach((word, index) => {
            let wordScore = wordScores[index];
            let icon = '';
            let color = '';
            if (wordScore >= 75) {
              icon = 'fa-check-circle';
              color = 'green';
            } else {
              icon = 'fa-exclamation-circle';
              color = 'orange';
            }
            feedbackHTML += `<span style="color: ${color};"><i class="fa ${icon}"></i> ${word} </span>`;
          });

          feedbackHTML += '<br><span class="mt-2"><small class="text-muted">Word-by-word feedback above is in beta & may not be 100% accurate.</small></span>';
        } else {
          console.error('Mismatch between number of words and scores');
        }

        this.updateSuggestionText(suggestionIndex, feedbackHTML, false);
      }
    }).catch(error => {
      console.log("Error in sending audio: ", error);
      if (suggestionDiv) {
        this.removeChatLoader(suggestionDiv);
        this.updateSuggestionText(suggestionIndex, '<p class="text-danger">Error loading feedback. Please try again.</p>', true);
      }
    });
  }

  emitPronunciationStarted() {
    // Emit the playended event
    const event = new CustomEvent('chat:pronounciationstarted');
    document.dispatchEvent(event);
  }

  // Function to add the loader
  addChatLoader(targetElement, message) {
    const loader = document.createElement('div');
    loader.className = 'pronunciation-loader mt-2';

    // display message if provided
    loader.innerHTML = `
          <p class="text-center">${message}</p>
    `;

    targetElement.appendChild(loader);
  }

  // Function to remove the loader
  removeChatLoader(targetElement) {
    const loader = targetElement.querySelector('.pronunciation-loader');
    if (loader) {
      targetElement.removeChild(loader);
    }

    this.isLoadingMessage = false;
  }

  updateSuggestionText(suggestionIndex, newText, isError) {
    const suggestionDiv = $(`#js-suggestion-${suggestionIndex}`);
    if (!suggestionDiv.data('original-text')) {
      suggestionDiv.data('original-text', suggestionDiv.html());
    }
    if (isError) {
      suggestionDiv.append(newText);
    } else {
      suggestionDiv.html(newText);
    }
    suggestionDiv.data('showing-pronunciation', true);
  }
}

export default PronunciationService;
