class ChatTranslationService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  fetchTranslation(chatMessageId, text, language, translation_provider= 'simple_openai') {
    return fetch('/langua/chat_translation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({ id: chatMessageId, text: text, language: language, translation_provider: translation_provider })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
  }
}

export default ChatTranslationService;
