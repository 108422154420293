import consumer from ".././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const userStoryEl = document.getElementById('js-user-story');

  if (userStoryEl) {
    let userStoryId = userStoryEl.dataset.userStoryId
    let userStoryStatus = userStoryEl.dataset.userStoryStatus

    if (userStoryStatus !== 'completed') {
      consumer.subscriptions.create({ channel: "UserStoryChannel", user_story_id: userStoryId }, {
        received(data) {
          if (data.status == 'completed' || data.status == 'failed') {
            window.location.reload();
          }
        }
      });
    }
  }
})
