class ChallengeMode {
  constructor(audioSync, playerService, disposePopover) {
    this.challenge = false; // Boolean to indicate whether challenge mode is on or off
    this.audioSync = audioSync; // Reference to the audio sync object
    this.playerService = playerService; // Reference to the player object
    this.disposePopover = disposePopover; // Reference to the disposePopover function
    this.challengeButton = document.getElementById('challenge')

    this.setupEventListeners();
  }

  setupEventListeners() {
    const that = this;
    this.challengeButton.addEventListener('click', function () {
      that.toggleChallenge();
    });
  }

  // Function to toggle challenge mode on and off
  toggleChallenge() {
    this.challenge = !this.challenge;
    this.audioSync.setChallenge(this.challenge);

    const words = document.querySelectorAll('.word');
    const currentActive = document.getElementsByClassName('active');
    if (currentActive.length > 0) {
      this.disposePopover(currentActive[0]);
    }

    if (this.challenge) {
      this.challengeButton.innerHTML = "👀";
      this.challengeButton.title = "Turn challenge mode off. This will show the text of the video.";
      words.forEach(word => {
        word.classList.add('challenge');
      });
    } else {
      this.challengeButton.innerHTML = "🙈";
      this.challengeButton.title = "Turn challenge mode on. This will hide the text so you can try to follow without reading and prepare yourself for real life conversations. You'll be able to reveal words when needed and click again to translate. Don't worry about understanding every word.";
      words.forEach(word => {
        word.classList.remove('challenge');
      });
    }
  }

  resetChallenge() {
    this.challenge = false;
  }

  // Function to hide or reveal a word when in challenge mode
  hideWord(element, boolean) {
    const words = element.childNodes;
    if (boolean) {
      element.classList.toggle('active-sentence');
      this.playerService.play();
    } else {
      words.forEach((word) => {
        word.classList.add('challenge');
        this.disposePopover(word);
      });
      element.classList.remove('active-sentence');
      this.playerService.pause();
    }
  }

  // Function to reveal a sentence when clicked in challenge mode
  revealSentence(element) {
    const activeSentences = document.getElementsByClassName('active-sentence');
    let activeSentencesWords = false;

    if (activeSentences.length > 0) {
      activeSentencesWords = activeSentences[0].childNodes[0].classList.contains('challenge');
    }

    if (element.dataset.startTime) {
      this.playerService.pause();
      this.playerService.setCurrentTime(parseFloat(element.dataset.startTime));
    }

    if (activeSentences.length > 0 && activeSentences[0] === element && activeSentencesWords) {
      this.hideWord(activeSentences[0], true);
    } else {
      if (activeSentences.length > 0) {
        this.hideWord(activeSentences[0], false);
      }
      activeSentencesWords = element.childNodes;
      element.classList.add('active-sentence');
      activeSentencesWords.forEach((word) => {
        word.classList.remove('challenge');
        this.disposePopover(word);
      });
    }
  }
}

export default ChallengeMode;
