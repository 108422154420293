import consumer from "../.././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const importVocabButton = document.getElementById('js-import-vocab-button');

  if (importVocabButton) {
    let userId = importVocabButton.dataset.id

  consumer.subscriptions.create({ channel: "UserImportVocabChannel", user_id: userId }, {
    received(data) {
      const notice = encodeURIComponent(data.notice);

      window.location.href = `/langua/vocabulary?notice=${notice}`;
    }
  });
  }
})
