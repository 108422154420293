import consumer from "../channels/consumer"
import NotificationService from "../account/notification_service";

document.addEventListener("turbolinks:load", function () {
  consumer.subscriptions.create({ channel: "NotificationChannel" }, {
    received(data) {
      let notificationName = data["notification_data"]["notification_name"];

      if (notificationName == 'daily_streak') {
        let message = data["notification_data"]["message"];
        let notificationType = data["notification_data"]["notification_type"];

        localStorage.setItem('showDailyStreakNotification', 'true');
        localStorage.setItem('dailyStreakNotificationMessage', message);
        localStorage.setItem('dailyStreakNotificationType', notificationType);
      }
    }
  });

  if (localStorage.getItem('showDailyStreakNotification') === 'true') {
    let message = localStorage.getItem('dailyStreakNotificationMessage')
    let notificationType = localStorage.getItem('dailyStreakNotificationType')

    const notification = new NotificationService({
      message: message,
      notificationType: notificationType
    })

    notification.show();

    // Clear localStorage
    localStorage.removeItem('showDailyStreakNotification');
    localStorage.removeItem('dailyStreakNotificationMessage');
    localStorage.removeItem('dailyStreakNotificationType');
  }
});
