function setupFlagWord(e, word) {
  $(this).find('.flag-icon').addClass('waving');
  let confirmFlaggingBox = `
                    <div class="modal" id="flagConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="flagConfirmationModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="flagConfirmationModalLabel">Confirm Flagging</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" id="flagConfirmationModalBody">
                            If you believe the translation of this word is incorrect, please confirm & we'll check it.
                            Keep in mind that the translation may not fit the sentence but may be correct in another context
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" id="cancel" data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-primary js-confirm-flag">Confirm</button>
                          </div>
                        </div>
                      </div>
                    </div> `;
  $('.translate-popover').popover('hide');
  let modal = $(confirmFlaggingBox).appendTo('body');
  let currentWord = word;
  modal.modal('show');
  function handleConfirmClick() {
    let word = encodeURIComponent(currentWord);
    let modalTitle = modal.find('#flagConfirmationModalLabel');
    let modalBody = modal.find('#flagConfirmationModalBody');
    let modalFooter = modal.find('.modal-footer');

    $.ajax({
      url: '/langua/dictionaries/' + word + '/flag',
      method: 'PATCH',
      success: function (response) {
        if (response.success) {
          modalTitle.html('Success! 🎉');
          modalBody.html('The translation has been flagged');
          modalFooter.remove();
        }
      },
      error: function (response) {
        if (response.error) {
          modalTitle.html('Error');
          modalBody.html('Something went wrong on our end! Please try again later');
          modalFooter.remove();
        }
      }
    });

    $('.js-confirm-flag').off('click', handleConfirmClick);
  }
  $('.js-confirm-flag').on('click', handleConfirmClick);
  e.stopPropagation();
}

module.exports = setupFlagWord;
