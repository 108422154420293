// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default includes for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.
require("account")
require("sprinkles")
require("clean_admin")
require("legacy/homepage")
require("contents")
require("flashcards")
require("dictionary")
require("podcasts")
require("common_words")
require("translator")

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.
