import CommonWordService from './common_word_service';

document.addEventListener("turbolinks:load", function () {

  const commonWordsContainerEl = document.getElementById('js-common-words-container');

  if (commonWordsContainerEl) {
    // Assuming CommonWordService is already imported or defined above
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const commonWordService = new CommonWordService(csrfToken);

    $('body').on('click', '#js-add-all-words-btn', function (event) {
      const intervalOffset = event.target.dataset.offset;
      commonWordService.addBatchWords(intervalOffset);
    });

    $('body').on('click', '#js-remove-all-words-btn', function (event) {
      const intervalOffset = event.target.dataset.offset;
      commonWordService.removeBatchWords(intervalOffset);
    });

    $('body').on('click', '.js-add-word-btn', function (event) {
      // Find the closest element with the class '.js-add-word-btn'
      let button = event.target.closest('.js-add-word-btn');

      if (button) {
        const word = button.dataset.word;
        const translation = button.dataset.translation;
        const index = button.dataset.index;
        const intervalOffset = button.dataset.offset;

        commonWordService.addWord(word, translation, index, intervalOffset);
      }
    });

    // Check if the common words button exists on the page
    const commonWordsBtns = document.querySelectorAll('.js-common-words-btn');
    if (commonWordsBtns.length > 0) {
      commonWordsBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
          const offset = btn.getAttribute('data-offset');
          const interval = btn.getAttribute('data-interval');
          const language = btn.getAttribute('data-language');
          commonWordService.fetchCommonWords(offset, interval, language);
        });
      });
    }
  }
});
