function displayResult(language, data, context, targetDiv) {
    let $targetDivEl = $(targetDiv)
    const contextClasses = {
        'similar words': 'js-flashcard-similar-words-btn',
        'usage examples': 'js-flashcard-usage-examples-btn',
        'phrases': 'js-flashcard-phrase-examples-btn',
    };

    let contextClassBtn = contextClasses[context] || '';

    if (data.length > 0) {
        let childEl = data.map((element, index)=> {
            return `<div class="flex">
                        <div class="d-flex justify-content-center py-1">
                            <span class="px-2">
                                ${element.source} = ${element.translation}
                                <a href="#" class="btn btn-sm btn-primary float-right ${contextClassBtn}" data-index="${index}">
                                    <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                                    <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
                                </a>
                            </span>
                        </div>
                    </div>`;
        }).join('');

        $targetDivEl.html(`${childEl}`);

        expandCardHeight(50)
    } else {
        $targetDivEl.html(`<span class="d-flex justify-content-center">No ${context} found.</span>`);
    }
}

function expandCardHeight(size) {
    let myDiv = document.getElementById('flashcard-back-div');
    if (myDiv.style.height == '') {
        let initialHeight = myDiv.offsetHeight;
        myDiv.style.height = initialHeight + 'px';
    }
    let currentHeight = parseInt(myDiv.style.height, 10);
    let newHeight = currentHeight + size;
    myDiv.style.height = newHeight + 'px';
}

module.exports = {
    displayResult,
    expandCardHeight
};
