import PronunciationService from "./pronunciation_service";

class SuggestedReplyService {
  constructor(csrfToken, dialectCode) {
    this.csrfToken = csrfToken;
    this.dialectCode = dialectCode;
    this.supportedPronunciationLanguages = ['french', 'english', 'spanish'];
    this.originalSuggestions = {};
  }

  fetch(chatId, chatMessageId, text, language, context = null) {
    return fetch('/langua/suggestions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({ chat_id: chatId, chat_message_id: chatMessageId, text: text, language: language, context: context })
    })
    .then(response => response.json())
  }

  isPronunciationSupported(language) {
    return this.supportedPronunciationLanguages.includes(language.toLowerCase());
  }

  showSuggestions(chatService, suggestions, language) {
    const modalHTML = `
      <div class="modal fade animate" id="chatSuggestionsModal" tabindex="-1" role="dialog" aria-labelledby="chatOptionsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document" style="align-items: flex-end;${window.innerWidth <= 768 ? 'margin-top: -85px;' : ''}">
          <div class="modal-content animate-bottom">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding-bottom:0px;">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row d-flex justify-content-center mb-4">
                ${this.suggestionsHTML(suggestions, language)}
              </div>

              <div class="chat-read-aloud text-center text-muted mt-2">
                Read out loud, then click on a reply to send it.
              </div>
              <div class="text-center mt-3">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    // Append the modal HTML to the body
    document.body.insertAdjacentHTML('beforeend', modalHTML);
    const modal = document.getElementById('chatSuggestionsModal');

    // Event listener for suggestion option buttons
    $(document).on('click', '.js-suggestion-option-btn', function(e) {
      e.preventDefault();

      const text = $(this).data('text');

      chatService.chatInput.value = text;
      chatService.handleSubmitForm(chatService, e);

      // close modal
      $(modal).modal('hide');
    });

    if (this.isPronunciationSupported(language)) {
      const pronunciationService = new PronunciationService(chatService.csrfToken, chatService.language, this.dialectCode);
      pronunciationService.updateSuggestionText = this.updateSuggestionText.bind(this);
      
      $(document).on('click', '.js-pronunciation-btn', function (e) {
        e.preventDefault();

        const pronunciationBtn = this;
        const suggestionText = $(this).data('text');
        const suggestionIndex = $(this).data('suggestion-index');
        const suggestionDiv = $(`#js-suggestion-${suggestionIndex}`);

        if (pronunciationService.alreadyRecording()) {
          pronunciationService.stopPronunciation(pronunciationBtn, suggestionText, suggestionIndex);
        } else {
          if (suggestionDiv.data('showing-pronunciation')) {
            // Show original suggestion
            suggestionDiv.html(suggestionDiv.data('original-text'));
            suggestionDiv.data('showing-pronunciation', false);
          }
          pronunciationService.startPronunciation(pronunciationBtn, suggestionText, suggestionIndex);
        }
      });
    }

    $(document).on('click', '.js-suggestion-translation-btn', function(e) {
      e.preventDefault();
      e.stopPropagation();

      // disable button to prevent multiple clicks
      e.target.disabled = true;

      const translationBtn = this;
      translationBtn.classList.add('pulse');

      if (e.handled !== true) {
        e.handled = true;
        const text = this.dataset.text

        chatService.translationService.translateSentence(text, (translatedData) => {
          if (translatedData.error) {
            translationBtn.classList.remove('pulse');
            translationBtn.remove();
            console.log(translatedData.error);
          } else {
            translationBtn.classList.remove('pulse');
            const translation = translatedData.translation;
            const suggestionBtn = $(translationBtn.parentNode.parentNode.querySelector('.js-suggestion-option-btn'))
            suggestionBtn.append(`<div class="chat-suggestion-translation font-italic mt-2">${translation}</div>`);

            translationBtn.remove();
          }
        });
      }
    });

    const closeModal = () => {
      // Tear down event listeners
      $(document).off('click', '.js-suggestion-option-btn');
      $(document).off('click', '.js-pronunciation-btn');
      $(document).off('click', '.js-suggestion-translation-btn');
      $(modal).modal('dispose')
      $(modal).remove();
    };

    $(modal).on('hidden.bs.modal', closeModal);

    $(modal).modal('show');
  }

  updateSuggestionText(suggestionIndex, newText, isError = false) {
    const suggestionDiv = $(`#js-suggestion-${suggestionIndex}`);
    const feedbackDiv = $(`#js-suggestion-feedback-${suggestionIndex}`);
    
    if (isError) {
      if (feedbackDiv.length === 0) {
        suggestionDiv.after(`<div id="js-suggestion-feedback-${suggestionIndex}" class="mt-2"></div>`);
      }
      $(`#js-suggestion-feedback-${suggestionIndex}`).html(newText);
    } else {
      if (!suggestionDiv.data('original-text')) {
        suggestionDiv.data('original-text', suggestionDiv.html());
      }
      suggestionDiv.html(newText);
      suggestionDiv.data('showing-pronunciation', true);
    }
  }

  suggestionsHTML(suggestions, language) {
    return suggestions.map((suggestion, index) => {
      this.originalSuggestions[index] = suggestion;
      return `
        <div class="col-12 mb-4 d-flex flex-column chat-message-content word-container" data-text="${suggestion}">
          <div id="js-suggestion-${index}" class="btn btn-secondary-cta-outline white-bg py-3 w-100 mb-2 js-suggestion-option-btn flex-grow-1" style="cursor: pointer; font-size: 1rem;" data-text="${suggestion}">
            ${suggestion.replace(/(\S+)/g, `<span class="js-audio-word word" style="cursor: pointer;" data-translated-text='${suggestion.replace(/"/g, '\\"').replace(/'/g, '&#39;')}' data-language="${language}">$1</span>`)}
          </div>
          <div class="chat-response-option text-center pt-1">
            <a href="javascript:void(0)" class="btn btn-chat-secondary js-play-chat-audio-btn mr-1" data-text="${suggestion}" data-language="${language}">
              <i class="fa fa-volume-up" aria-hidden="true"></i>
            </a>
            
            ${this.isPronunciationSupported(language) ? `
            <a href="javascript:void(0)" class="btn btn-pronunciation-secondary js-pronunciation-btn ml-1" data-text="${suggestion}" data-language="${language}" data-suggestion-index="${index}">
              <i class="fa fa-microphone" aria-hidden="true"></i>
              Say it
            </a>
            ` : ''}
            
            <a href="javascript:void(0)" class="btn btn-chat-secondary js-suggestion-translation-btn ml-1" data-text="${suggestion}" data-language="${language}">
              <i class="fa fa-language" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      `;
    }).join('');
  }
}

export default SuggestedReplyService
