class ChatTopicService {
  constructor(chatElementContainer, chatElement) {
    this.chatElementContainer = chatElementContainer;
    this.chatElement = chatElement;
    this.topics = null;
  }

  showSubCategories(topicCategory, subCategories, container) {
    container.empty();

    subCategories.forEach(subCategory => {
      const buttonHtml = `<div class="col-12 col-md-4 col-lg-4 col-xl-3">
                            <button type="button" class="btn btn-secondary-cta-outline white-bg py-3 chat-option-button w-100 mb-2 js-sub-category-btn" data-topic-category="${topicCategory}" data-subtopic-category="${subCategory.key}" data-topics='${JSON.stringify(subCategory.topics)}'>
                              ${subCategory.label}
                          </button>
                          </div>`;
      if (topicCategory === 'debate') {
        container.addClass('scroll-options');
      }

      container.append(buttonHtml);
    });

    $('.js-chat-form-back-button').removeClass('d-none');

    const that = this;
    $('body').on('click', '.js-chat-form-back-button', function (e) {
      // remove all js-sub-category-btn in container
      container.empty();
      $('.js-chat-form-back-button').addClass('d-none');
      that.showChatTopics();
    })

    this.hideChatTopics();
  }

  showSubOptions(topicCategory, subtopicCategory, topics) {
    this.topics = topics;
    const container = $('.js-sub-options-container');
    container.empty();

    topics.forEach(topic => {
      const buttonHtml = `<div class="col-md-4 col-lg-4 col-xl-3">
                            <button type="button" class="btn btn-secondary-cta-outline white-bg py-3 chat-option-button w-100 mb-2 js-sub-chat-option-btn" data-topic="${topic.key}" data-topic-category="${topicCategory}" data-subtopic-category="${subtopicCategory}">
                              ${topic.label}
                          </button>
                          </div>`;
      container.append(buttonHtml);
    });

    container.removeClass('d-none');
  }

  showChatTopics() {
    $('.js-main-chat-option-btn').removeClass('d-none');
    $('.js-continue-conversation-btn').removeClass('d-none');
  }

  hideChatTopics() {
    $('.js-main-chat-option-btn').addClass('d-none');
    $('.js-continue-conversation-btn').addClass('d-none');
  }

  hideTopicMenu() {
    this.chatElementContainer.classList.remove('chat-content-w-centered');
    this.chatElementContainer.classList.add('chat-content-w-default');
    this.chatElement.classList.remove('chat-content-centered');
    this.chatElement.classList.add('chat-content-default');
    if (this.chatElement.querySelector('.js-chat-intro-container')) {
      this.chatElement.querySelector('.js-chat-intro-container').remove();
    }
  }
}

export default ChatTopicService;
