class TranscriptHandler {
  constructor(chatInput) {
    this.chatInput = chatInput;
    this.transcriptParts = [];
    this.finalizedText = '';
    this.currentPartialText = '';
    this.setupInputListener();
  }

  utteranceText(data) {
    const words = data.channel.alternatives[0].words;
    return words.map(word => word.punctuated_word || word.word).join(' ').trim();
  }

  processTranscript(data) {
    const content = this.utteranceText(data);

    if (content.length === 0 && !data.speech_final) {
      return;
    }

    if (data.is_final) {
      this.finalizedText = this.combineTexts(this.finalizedText, content);
      this.currentPartialText = '';
    } else {
      this.currentPartialText = content;
    }

    if (data.speech_final) {
      this.finalizedText = this.combineTexts(this.finalizedText, this.currentPartialText);
      this.currentPartialText = '';
    }

    this.updateChatInput();
  }

  combineTexts(...texts) {
    return texts.filter(text => text && text.trim().length > 0).join(' ').trim();
  }

  updateChatInput() {
    this.chatInput.value = this.combineTexts(this.finalizedText, this.currentPartialText);
  }

  clearTranscript() {
    this.finalizedText = '';
    this.currentPartialText = '';
  }

  setupInputListener() {
    this.chatInput.addEventListener('input', () => {
      if (this.chatInput.value === '') {
        this.clearTranscript();
      }
    });
  }
}

export default TranscriptHandler;
