import consumer from "../.././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const subscriptionEl = document.getElementById('js-subscription-container');

  if (subscriptionEl) {
    let userId = subscriptionEl.dataset.id

    consumer.subscriptions.create({ channel: "UserSubscriptionChannel", user_id: userId }, {
      received(data) {
        const subscriptionSuccessEl = document.getElementById('js-subscription-success');
        if (!subscriptionSuccessEl) {
          window.location.href = '/langua/subscriptions/success_feedback';
        }
      }
    });
  }
})
