class PopoverService {
  constructor(translationService, language, contentId, contentType, csrfToken, premiumAccount = 'false', hideTranslationSentence = false, playerService = null) {
    this.translationService = translationService;
    this.playerService = playerService;

    this.language = language;
    this.usageExamplesData = {};
    this.phraseExamplesData = {};
    this.similarWordsData = {};
    this.translateSentenceData = {};
    this.translateMultipleData = {};

    this.currentWord;
    this.translatedWord;
    this.translatedSentence;
    this.contextSentence;
    this.phraseSelected;

    this.soundPlaying = false;

    this.contentId = contentId;
    this.contentType = contentType;
    this.csrfToken = csrfToken;
    this.premiumAccount = premiumAccount;
    this.hideTranslationSentence = hideTranslationSentence;

    this.synthesize = require('./../contents/play_audio_word');
    this.setupFlagWord = require('./../contents/flag_word');
    this.batchCreateFlashcards = require('./../contents/batch_create_flashcards');

    this.setupEventListeners()
  }

  setupEventListeners() {
    const that = this;

    $('body').on('click', '.js-play-audio-btn', function (e) {
      e.preventDefault();
      that.playAudioHandler(this)
    });

    // on click of js-translate-sentence
    $('body').on('click', '.js-translate-sentence', function (e) {
      e.preventDefault();
      that.translateSentenceHandler(this);
    });

    $('body').on('click', '.js-translate-multiple', function (e) {
      e.preventDefault();
      that.translateMultipleHandler(this);
    });

    // on click of js-usage-examples and js-similar-words
    $('body').on('click', '.js-usage-examples', function (e) {
      e.preventDefault();
      that.usageExamplesHandler(this);
    });

    $('body').on('click', '.js-phrase-examples', function (e) {
      e.preventDefault();
      that.phraseExamplesHandler(this);
    });

    $('body').on('click', '.js-similar-words', function (e) {
      e.preventDefault();
      that.similarWordsHandler(this);
    });

    $('body').on('click', '.js-flag-btn', function (e) {
      e.preventDefault();
      that.flagWordHandler(e);
    });

    $('body').on('click', '.js-subscriptions-modal', function (e) {
      $('#subscriptionModal').modal('show');
      that.disposeAllPopovers()
      e.preventDefault();
    });


    $('body').on('click', '.popover-collapse-link', function (e) {
      e.preventDefault();
      $(this).next().toggle();
    });

    // setup dismiss popover
    $(document).on('click', function (e) {
      $('.translate-popover').each(function () {
        // the 'is' for buttons that trigger popups
        // the 'has' for icons within a button that triggers a popup
        var isClickedElementRelatedToPopover = $(this).is(e.target) || $(this).has(e.target).length !== 0;
        var isPopoverShown = $(document).find('.translate-popover:visible').length;

        // Check if the clicked element is related to this popover and it's shown
        if (!isClickedElementRelatedToPopover && isPopoverShown) {
          that.disposeAllPopovers();
        }
      });
    });

    $('body').on('click', '#cancel-btn', function (e) {
      e.preventDefault();
      $('#translation-flag-modal').popover('dispose');
    });

    $('body').on('click', '.js-flashcard-btn', function (e) {
      e.preventDefault();

      let flashcard_type = 'word';

      if (that.phraseSelected) {
        let words = that.currentWord.trim().split(/\s+/);
        let wordsCount = words.length;

        if (wordsCount < 3) {
          flashcard_type = 'word'
        } else if (wordsCount < 5) {
          flashcard_type = 'phrase'
        } else {
          flashcard_type = 'sentence'
        }
      }

      let flashcardData = {
        front: that.currentWord,
        back: that.translatedWord,
        language: that.language,
        context_sentence: that.contextSentence,
        translated_sentence: that.translatedSentence,
        flashcardable_id: that.contentId,
        flashcardable_type: that.contentType,
        flashcard_type: flashcard_type
      }
      that.flashcardHandler(this, flashcardData);
    });

    $('body').on('click', '.js-flashcard-translated-sentence-btn', function (e) {
      e.preventDefault();

      let flashcardType = 'sentence';
      let flashcardData = [{
        front: that.contextSentence,
        back: that.translatedSentence.replace(/<\/?strong>/gi, ""),
        flashcard_type: flashcardType
      }];

      if (flashcardData.length > 0) {
        that.batchCreateFlashcardsHandler(this, flashcardData, flashcardType, that.language, that.contentId, that.contentType)
      }
    });

    $('body').on('click', '.js-flashcard-multiple-btn', function (e) {
      e.preventDefault();

      let flashcardType = 'word';
      let translations = Object.values(that.translateMultipleData[that.currentWord].translations);
      let translation = translations[$(this).data('index')];
      let flashcardData = [{
        front: that.currentWord,
        back: translation.translation
      }];

      if (flashcardData.length > 0) {
        that.batchCreateFlashcardsHandler(this, flashcardData, flashcardType, that.language, that.contentId, that.contentType)
      }
    });

    $('body').on('click', '.js-flashcard-add-all-multiple-btn', function (e) {
      e.preventDefault();

      let flashcardType = 'word';
      let translations = Object.values(that.translateMultipleData[that.currentWord].translations);
      let translation = translations.map((translation) => { return `${translation.translation}` }).join(' / ');
      let flashcardData = [{
        front: that.currentWord,
        back: translation
      }];

      if (flashcardData.length > 0) {
        that.batchCreateFlashcardsHandler(this, flashcardData, flashcardType, that.language, that.contentId, that.contentType)
      }
    });

    $('body').on('click', '.js-flashcard-usage-examples-btn', function (e) {
      e.preventDefault();

      let flashcardType = 'sentence';
      let usageExamples = Object.values(that.usageExamplesData[that.currentWord].result.examples);
      let usageExample = usageExamples[$(this).data('index')];
      let flashcardData = [{
        front: usageExample.source,
        back: usageExample.translation
      }];

      if (flashcardData.length > 0) {
        that.batchCreateFlashcardsHandler(this, flashcardData, flashcardType, that.language, that.contentId, that.contentType)
      }
    });

    $('body').on('click', '.js-flashcard-phrase-examples-btn', function (e) {
      e.preventDefault();

      let flashcardType = 'phrase';
      let phraseExamples = Object.values(that.phraseExamplesData[that.currentWord].result.phrases);
      let phraseExample = phraseExamples[$(this).data('index')];
      let flashcardData = [{
        front: phraseExample.source,
        back: phraseExample.translation
      }];

      if (flashcardData.length > 0) {
        that.batchCreateFlashcardsHandler(this, flashcardData, flashcardType, that.language, that.contentId, that.contentType)
      }
    });

    $('body').on('click', '.js-flashcard-similar-words-btn', function (e) {
      e.preventDefault();

      let flashcardType = 'word';
      let similarWords = Object.values(that.similarWordsData[that.currentWord].result.similar_words);
      let similarWord = similarWords[$(this).data('index')];
      let flashcardData = [{
        front: similarWord.source,
        back: similarWord.translation
      }];

      if (flashcardData.length > 0) {
        that.batchCreateFlashcardsHandler(this, flashcardData, flashcardType, that.language, that.contentId, that.contentType)
      }
    });

    $('body').on('click', '.btn-close', function (e) {
      e.preventDefault();
      let $btnClose = $(this);
      let $popover = $btnClose.closest('.popover');
      let popoverId = $popover.attr('id');
      let element = `[aria-describedby="${popoverId}"]`;
      let $popoverTrigger = $(element);
      $popoverTrigger.popover('dispose');
      $popoverTrigger.removeClass('active');

      if (that.playerService) {
        that.playerService.togglePlayer();
      }
    });
  }

  // Method to display a loading popover
  showLoadingPopover(wordElement) {
    const loadingPopoverContent = `
      <div class="popover translate-popover skeleton show bs-popover-bottom" role="tooltip">
        <div class="arrow"></div>
        <h3 class="popover-header"></h3>
        <div class="popover-body">
          <div class="popover-content">
            <div class="d-flex justify-content-between align-items-center">
              <div class="skeleton skeleton-text"></div>
            </div>
            <div class="skeleton skeleton-text skeleton-text__body"></div>
            <div class="mt-2 skeleton skeleton-footer"></div>
            <div class="skeleton skeleton-footer"></div>
          </div>
        </div>
      </div>
    `;

    $(wordElement).popover({
      template: '<div class="popover translate-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
      title: '',
      placement: 'top',
      html: true,
      trigger: 'manual',
      animation: false,
      container: 'body',
      fallbackPlacement: ['left', 'right', 'top', 'bottom'],
      content: loadingPopoverContent
    });

    $(wordElement).popover('show');
  }

  // Method to update the popover content after translation
  updatePopoverContent(wordElement, data, phraseSelected = false) {
    // Your logic to update the popover content goes here
    this.currentWord = data.word;
    this.translatedWord = data.translation;
    this.phraseSelected = phraseSelected;
    this.translatedSentence = data.translated_sentence ? data.translated_sentence : '';

    let fullTranslationSentence = 'No sentence given';
    this.contextSentence = data.context_sentence ? data.context_sentence : '';

    if (data.translated_infinitive) {
      this.translatedWord = data.translated_infinitive;
      this.currentWord = data.word_infinitive;
    } else if (data.translated_word) {
      this.translatedWord = data.translated_word;
    }

    let displayWordInfo = '';
    if (wordElement.dataset.wordInfo) {
      let wordInfo = JSON.parse(wordElement.dataset.wordInfo);

      if (wordInfo.human_readable) {
        displayWordInfo = wordInfo.human_readable;
      }
    }

    if (this.contextSentence !== '' && this.translatedSentence !== '' && this.translatedSentence !== null) {
      let boldedWord = `<strong>${this.currentWord}</strong>`;
      this.contextSentence = this.contextSentence.replace(this.currentWord, boldedWord);
      fullTranslationSentence = `${this.contextSentence} = ${this.translatedSentence}`;
    }

    let popoverContent = `
<div class="popover-content">
  <div class="d-flex justify-content-between align-items-center">
    ${data.error ? `<h5 class="text-danger">${data.error}</h5>` : `<h5>${this.currentWord} = ${this.translatedWord}</h5>`}
  </div>

  ${this.phraseSelected ? '' : `
    ${displayWordInfo ? `<p class="text-muted">${displayWordInfo}</p>` : ''}
    
    ${this.premiumAccount == 'true' ?
      `<div class="mt-0">
        <a class="popover-collapse-link js-translate-multiple" href="#">All meanings</a>
        <div class="collapse js-multiple-translation-card"></div>
      </div>`
      :
      `<div class="mt-2">
        <a class="js-subscriptions-modal subscription-link" href="#">All meanings</a>
      </div>`
    }
  
    ${!this.hideTranslationSentence ?
      `<div class="mt-2">
        <a class="popover-collapse-link js-translate-sentence" href="#">Translate sentence</a>
        <div class="collapse js-translate-sentence-card"></div>
      </div>`
      : ''}
    
    ${this.premiumAccount == 'true' ?
      `<div class="mt-2">
          <a class="popover-collapse-link js-usage-examples" href="#">Usage examples</a>
          <div class="collapse js-usage-examples-card"></div>
      </div>
      <div class="mt-2">
          <a class="popover-collapse-link js-phrase-examples" href="#">Phrases</a>
          <div class="collapse js-phrase-examples-card"></div>
      </div>
      <div class="mt-2">
          <a class="popover-collapse-link js-similar-words" href="#">Similar words</a>
          <div class="collapse js-similar-words-card"></div>
      </div>`
      :
      `<div class="mt-2">
          <a class="js-subscriptions-modal subscription-link" href="#">Usage examples</a>
      </div>
      <div class="mt-2">
          <a class="js-subscriptions-modal subscription-link" href="#">Phrases</a>
      </div>
      <div class="mt-2">
          <a class="js-subscriptions-modal subscription-link" href="#">Similar words</a>
      </div>`
    }
  `}

  <div class="btn-group mt-3 w-100" role="group" aria-label="Basic example">
    ${this.premiumAccount == 'true' ?
      `<a href="#" class="btn btn-primary js-flashcard-btn flex-grow-1">
          <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
          <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
      </a>`
      :
      `<a href="#" class="btn btn-primary js-subscriptions-modal flex-grow-1">
          <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
          <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
      </a>`
    }
    
    <a href="#" class="btn btn-secondary js-play-audio-btn">
      <i class="fa fa-volume-up" aria-hidden="true"></i>
    </a>
    <a href="#" class="btn btn-secondary js-flag-btn">
      <i class="fa flag-icon" aria-hidden="true"> 🚩 </i>
    </a>

    <a href="#" class="btn btn-secondary btn-close"><i class="fa fa-close" style="color: white;" aria-hidden="true"></i></a>
  </div>
</div>`;

    wordElement.classList.add('active');
    $(wordElement).data('bs.popover').config.content = popoverContent;
    $(wordElement).popover('show');
  }

  // Method to handle flashcard creation
  flashcardHandler(element, flashcardData) {
    let $btn = $(element);
    let $plusIcon = $btn.find('.plus-icon');
    let $checkIcon = $btn.find('.check-icon');

    fetch('/langua/flashcards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        flashcard: flashcardData
      })
    })
      .then(response => response.json())
      .then(function (data) {

        // Animate the plus icon
        $plusIcon.css({
          transition: 'transform 0.3s ease-in-out',
          transform: 'rotate(45deg) scale(0)'
        });

        // Wait for the transition to finish, then display the check icon
        setTimeout(function () {
          $plusIcon.hide();

          $checkIcon.removeClass('d-none');
          $checkIcon.css({
            transition: 'transform 0.3s ease-in-out',
            transform: 'rotate(-45deg) scale(0)',
            display: 'inline-block'
          });

          setTimeout(function () {
            $checkIcon.css({
              transform: 'rotate(0deg) scale(1)'
            });

            setTimeout(function () {
              let $popover = $btn.closest('.popover');
              let popoverId = $popover.attr('id');
              let element = `[aria-describedby="${popoverId}"]`;
              let $popoverTrigger = $(element);
              $popoverTrigger.popover('dispose');
              $popoverTrigger.removeClass('active');
            }, 300);
          }, 50); // Wait for the check icon to be rendered before starting the transition
        }, 300); // Matches the duration of the plus icon's transition
      })
  }

  // Method to handle batch flashcard creation
  batchCreateFlashcardsHandler(element, flashcardData, flashcardType, language, contentId, contentType) {
    this.batchCreateFlashcards(element, flashcardData, flashcardType, language, contentId, contentType);
  }

  // Method to play audio
  playAudioHandler(element) {
    this.synthesize(element, this.currentWord, this.language, this.soundPlaying)
  }

  displayTranslateSentence(translateSentenceEl) {
    const fullTranslationSentence = `${this.contextSentence} = ${this.translatedSentence}`;

    translateSentenceEl.html(`
    ${this.premiumAccount == 'true' ?
      `<ul class="list-group list-group-flush"><li class="list-group-item">${fullTranslationSentence}<a href="#" class="btn btn-sm btn-primary js-flashcard-translated-sentence-btn float-right">
        <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
        <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
      </a></li></ul>`
      :
      `<ul class="list-group list-group-flush"><li class="list-group-item"><a href="#" class="btn btn-sm btn-primary float-right js-subscriptions-modal">
        <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
        <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
      </a></li></ul>`
      }`)
  }

  displayMultipleTranslations(translatedData) {
    let multipleTranslationCardEl = $('.js-multiple-translation-card');
    if (translatedData.translations.length > 0) {
      let translations = translatedData.translations.map((translation, index) => {
        return `<li class="list-group-item">${translation.translation}
              <a href="#" class="btn btn-sm btn-primary js-flashcard-multiple-btn float-right" data-index="${index}">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
              </a>
                </li>`;
      }).join('');

      let addAllTranslations = ''

      if (translatedData.translations.length > 1) {
        addAllTranslations = `<li class="list-group-item">
                                <a href="#" class="btn btn-sm btn-primary js-flashcard-add-all-multiple-btn float-right">
                                    <span class="plus-icon">Save all in one flashcard</span>
                                    <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
                                </a>
                              </li>`;
      }

      multipleTranslationCardEl.html(`<ul class="list-group list-group-flush">${translations + addAllTranslations}</ul>`);
    } else {
      multipleTranslationCardEl.html(`<p class="text-muted">No translations found.</p>`);
    }
  }

  displayError(error, containerEl) {
    console.log(error);
    containerEl.html(`${error}`);
  }

  translateMultipleHandler(element) {
    let word = this.currentWord;
    let that = this;
    let multipleTranslationCardEl = $('.js-multiple-translation-card');

    if (this.translateMultipleData[word]) {
      const allTranslations = this.translateMultipleData[word];
      this.displayMultipleTranslations(allTranslations);
      return;
    }

    // load skeleton on js-usage-examples-card
    multipleTranslationCardEl.html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

    that.translationService.translateMultiple(word, (translatedData) => {
      if (translatedData.error) {
        const containerEl = $('.js-multiple-translation-card');
        this.displayError(translatedData.error, containerEl)
      } else {
        this.translateMultipleData[word] = translatedData;
        this.displayMultipleTranslations(translatedData);
      }
    });
  }

  translateSentenceHandler(element) {
    let word = this.currentWord;
    let that = this;
    let translateSentenceEl = $('.js-translate-sentence-card');

    if (this.translateSentenceData[word]) {
      this.translatedSentence = this.translateSentenceData[word];
      this.displayTranslateSentence(translateSentenceEl);
      return;
    }

    // load skeleton on js-usage-examples-card
    translateSentenceEl.html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

    that.translationService.translateSentence(this.contextSentence, (translatedData) => {
      if (translatedData.error) {
        this.displayError(translatedData.error, translateSentenceEl)
      } else {
        this.translatedSentence = translatedData.translation;
        this.displayTranslateSentence(translateSentenceEl);
      }
    });
  }

  // Placeholder for usage examples
  usageExamplesHandler(element) {
    let word = this.currentWord;
    let that = this;
    let usageExamplesCardEl = $('.js-usage-examples-card');

    if (this.usageExamplesData[word]) {
      this.displayUsageExamples(this.usageExamplesData[word]);
      return;
    }

    // load skeleton on js-usage-examples-card
    usageExamplesCardEl.html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

    fetch('/langua/usage_examples', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({word: word, language: this.language})
    })
      .then(response => response.json())
      .then(function (data) {
        if (data.error) {
          $('.js-usage-examples-card').html('<p>There was an error finding usage examples. Please try again later.</p>');
          console.log(data.error);
        } else {
          that.usageExamplesData[word] = data;
          that.displayUsageExamples(data);
        }
      })
  }

  phraseExamplesHandler(element) {
    let word = this.currentWord;
    let that = this;
    let phraseExamplesCardEl = $('.js-phrase-examples-card');

    if (this.phraseExamplesData[word]) {
      this.displayPhraseExamples(this.phraseExamplesData[word]);
      return;
    }

    // load skeleton on js-phrase-examples-card
    phraseExamplesCardEl.html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

    fetch('/langua/phrase_examples', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({word: word, language: this.language})
    })
        .then(response => response.json())
        .then(function (data) {
          if (data.error) {
            $('.js-phrase-examples-card').html('<p>There was an error finding phrase examples. Please try again later.</p>');
            console.log(data.error);
          } else {
            that.phraseExamplesData[word] = data;
            that.displayPhraseExamples(data);
          }
        })
  }

  // Placeholder for similar words
  similarWordsHandler(element) {
    let word = this.currentWord;
    let that = this;
    let $similarWordsCardEl = $(".js-similar-words-card")

    if (this.similarWordsData[word]) {
      this.displaySimilarWords(this.similarWordsData[word]);
      return;
    }

    // load skeleton on js-usage-examples-card
    $similarWordsCardEl.html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

    fetch('/langua/similar_words', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({word: word, language: this.language})
    })
      .then(response => response.json())
      .then(function (data) {
        if (data.error) {
          $('.js-similar-words-card').html('<p>There was an error finding similar words. Please try again later.</p>');
          console.log(data.error);
        } else {
          that.similarWordsData[word] = data;
          that.displaySimilarWords(data);
        }
      });
  }

  flagWordHandler(element) {
    this.setupFlagWord(element, this.currentWord);
  }

  displayUsageExamples(data) {
    if (Object.keys(data.result).length > 0) {
      let usageExamplesCardEl = $('.js-usage-examples-card');
      let result = data.result
      if (result.examples.length > 0) {
        let examples = result.examples.map((example, index) => {
          let word = data.word;

          // bold word in example - find word ignoring case and accent for languages like Spanish and replace with bolded word
          let regex = new RegExp(word, 'gi');
          let boldedWord = `<strong>${word}</strong>`;
          let boldedExample = example.source.replace(regex, boldedWord);
          return `<li class="list-group-item">${boldedExample} = ${example.translation}
              <a href="#" class="btn btn-sm btn-primary js-flashcard-usage-examples-btn float-right" data-index="${index}">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
              </a>
                </li>`;
        }).join('');

        usageExamplesCardEl.html(`<ul class="list-group list-group-flush">${examples}</ul>`);
      } else {
        usageExamplesCardEl.html(`<p class="text-muted">No usage examples found.</p>`);
      }
    } else {
      usageExamplesCardEl.html(`<p class="text-muted">No usage examples found.</p>`);
    }
  }

  displayPhraseExamples(data) {
    let phraseExamplesCardEl = $('.js-phrase-examples-card');

    if (Object.keys(data.result).length > 0) {
      let result = data.result
      if (result.phrases.length > 0) {
        let examples = result.phrases.map((example, index) => {
          let word = data.word;

          // bold word in example - find word ignoring case and accent for languages like Spanish and replace with bolded word
          let regex = new RegExp(word, 'gi');
          let boldedWord = `<strong>${word}</strong>`;
          let boldedExample = example.source.replace(regex, boldedWord);
          return `<li class="list-group-item">${boldedExample} = ${example.translation}
              <a href="#" class="btn btn-sm btn-primary js-flashcard-phrase-examples-btn float-right" data-index="${index}">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
              </a>
                </li>`;
        }).join('');

        phraseExamplesCardEl.html(`<ul class="list-group list-group-flush">${examples}</ul>`);
      } else {
        phraseExamplesCardEl.html(`<p class="text-muted">No phrase examples found.</p>`);
      }
    } else {
      phraseExamplesCardEl.html(`<p class="text-muted">No phrase examples found.</p>`);
    }
  }

  displaySimilarWords(data) {
    if (Object.keys(data.result).length > 0) {
      let $similarWordsCardEl = $(".js-similar-words-card")
      let result = data.result;
      if (result.similar_words.length > 0) {
        let similarWords = result.similar_words.map((similarWord, index) => {
          return `<li class="list-group-item">${similarWord.source} = ${similarWord.translation}
              <a href="#" class="btn btn-sm btn-primary js-flashcard-similar-words-btn float-right" data-index="${index}">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
              </a>
                </li>`;
        }).join('');

        $similarWordsCardEl.html(`<ul class="list-group list-group-flush">${similarWords}</ul>`);

        $similarWordsCardEl.html(`<ul class="list-group list-group-flush">${similarWords}</ul>`);
      } else {
        $similarWordsCardEl.html(`<p>No similar words found.</p>`);
      }
    } else {
      $similarWordsCardEl.html(`<p>No similar words found.</p>`);
    }
  }

  disposePopover(element) {
    $('.active').popover('dispose')
    element.classList.remove('active');
  }

  disposeAllPopovers() {
    const activeWords = document.getElementsByClassName('active')
    for (let i = 0; i < activeWords.length; i++) {
      this.disposePopover(activeWords[i])
    }
  }

}

export default PopoverService;
