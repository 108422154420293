document.addEventListener("turbolinks:load", function () {
  const flashcardWordElement = document.getElementById('flashcard_front_word');

  if (flashcardWordElement) {
    let usageExamplesData = {};
    const currentWord = flashcardWordElement && flashcardWordElement.dataset.word;
    const translatedWord = flashcardWordElement && flashcardWordElement.dataset.translatedWord;
    const flashcardLanguageElement = document.getElementById('flashcard_word_language');
    const language = flashcardLanguageElement && flashcardLanguageElement.dataset.language;
    const { displayResult, expandCardHeight } = require('./display_result');
    const batchCreateFlashcards = require('./../contents/batch_create_flashcards');

    $('body').on('click', '.js-flashcards-usage-examples', function(e) {
      e.preventDefault();

      usageExamples(currentWord);
    });

    $('body').on('click', '.js-flashcard-usage-examples-btn', function(e) {
      e.preventDefault();

      if (flashcardWordElement !== null) {
        let flashcardID = flashcardWordElement.dataset.flashcardId
        let flashcardType = 'sentence';
        let usageExamples = Object.values(usageExamplesData[currentWord].result.examples);
        let usageExample;

        if (usageExamples.length > 0) {
          usageExample = usageExamples[$(this).data('index')];
        } else {
          usageExample = usageExamples[0];
        }

        let flashcardData = [{
          front: usageExample.source,
          back: usageExample.translation
        }];

        if (flashcardData.length > 0) {
          batchCreateFlashcards(this, flashcardData, flashcardType, language, flashcardID, 'Flashcard');
        }
      }
    });

    function usageExamples(word) {
      if (usageExamplesData[word]) {
        displayResult(language, usageExamplesData[word].result.examples, "usage examples", ".js-usage-examples-card");
        return;
      }

      $('.js-usage-examples-card').html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

      expandCardHeight(25)

      fetch('/langua/usage_examples', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        credentials: 'same-origin',
        body: JSON.stringify({ prompt: 'flashcard', word: word, language: language, translated_word: translatedWord })
      })
        .then(response => response.json())
        .then(function (data) {
          if (data.error) {
            $('.js-usage-examples-card').html('<span class="d-flex justify-content-center">There was an error finding usage examples. Please try again later.</span>');
          } else {
            if (Object.keys(data.result).length > 0) {
              usageExamplesData[word] = data;

              displayResult(language, data.result.examples, "usage examples", ".js-usage-examples-card");
            } else {
              $(".js-usage-examples-card")
                .html(`<span class="d-flex justify-content-center">No usage examples found.</span>`);
            }
          }
        })
    }
  }
});
