import PopoverService from './popover_service';

class BottomUpPopoverService extends PopoverService {
  constructor(translationService, language, contentId, contentType, csrfToken, premiumAccount = 'false', hideTranslationSentence = false, playerService = null) {
    super(translationService, language, contentId, contentType, csrfToken, premiumAccount, hideTranslationSentence, playerService);
    this.bottomUpWindow = null;
  }

  showLoadingPopover(wordElement) {
    const loadingContent = `
      <div class="bottom-up-content">
        <div class="skeleton skeleton-text"></div>
        <div class="skeleton skeleton-text skeleton-text__body"></div>
        <div class="mt-2 skeleton skeleton-footer"></div>
        <div class="skeleton skeleton-footer"></div>
      </div>
    `;
    this.showBottomUpWindow(loadingContent);
  }

  updatePopoverContent(wordElement, data, phraseSelected = false) {
    this.currentWord = data.word;
    this.translatedWord = data.translation;
    this.phraseSelected = phraseSelected;
    this.translatedSentence = data.translated_sentence ? data.translated_sentence : '';

    let fullTranslationSentence = 'No sentence given';
    this.contextSentence = data.context_sentence ? data.context_sentence : '';

    if (data.translated_infinitive) {
      this.translatedWord = data.translated_infinitive;
      this.currentWord = data.word_infinitive;
    } else if (data.translated_word) {
      this.translatedWord = data.translated_word;
    }

    let displayWordInfo = '';
    if (wordElement.dataset.wordInfo) {
      let wordInfo = JSON.parse(wordElement.dataset.wordInfo);

      if (wordInfo.human_readable) {
        displayWordInfo = wordInfo.human_readable;
      }
    }

    if (this.contextSentence !== '' && this.translatedSentence !== '' && this.translatedSentence !== null) {
      let boldedWord = `<strong>${this.currentWord}</strong>`;
      this.contextSentence = this.contextSentence.replace(this.currentWord, boldedWord);
      fullTranslationSentence = `${this.contextSentence} = ${this.translatedSentence}`;
    }

    let popoverContent = `
      <div class="bottom-up-content">
        <div class="d-flex justify-content-between align-items-center">
          ${data.error ? `<h5 class="text-danger">${data.error}</h5>` : `<h5>${this.currentWord} = ${this.translatedWord}</h5>`}
        </div>

      ${this.phraseSelected ? '' : `
        ${displayWordInfo ? `<p class="text-muted">${displayWordInfo}</p>` : ''}
        
        ${this.premiumAccount == 'true' ?
          `<div class="mt-0">
            <a class="popover-collapse-link js-translate-multiple" href="#">All meanings</a>
            <div class="collapse js-multiple-translation-card"></div>
          </div>`
          :
          `<div class="mt-2">
            <a class="js-subscriptions-modal subscription-link" href="#">All meanings</a>
          </div>`
        }

        ${!this.hideTranslationSentence ?
          `<div class="mt-2">
            <a class="popover-collapse-link js-translate-sentence" href="#">Translate sentence</a>
            <div class="collapse js-translate-sentence-card"></div>
          </div>`
          : ''}
        
        ${this.premiumAccount == 'true' ?
          `<div class="mt-2">
              <a class="popover-collapse-link js-usage-examples" href="#">Usage examples</a>
              <div class="collapse js-usage-examples-card"></div>
          </div>
          <div class="mt-2">
              <a class="popover-collapse-link js-phrase-examples" href="#">Phrases</a>
              <div class="collapse js-phrase-examples-card"></div>
          </div>
          <div class="mt-2">
              <a class="popover-collapse-link js-similar-words" href="#">Similar words</a>
              <div class="collapse js-similar-words-card"></div>
          </div>`
          :
          `<div class="mt-2">
              <a class="js-subscriptions-modal subscription-link" href="#">Usage examples</a>
          </div>
          <div class="mt-2">
              <a class="js-subscriptions-modal subscription-link" href="#">Similar words</a>
          </div>`
        }
      `}

        <div class="btn-group mt-3 custom-btn-group" role="group" aria-label="Basic example">
          ${this.premiumAccount == 'true' ?
            `<a href="#" class="btn btn-primary js-flashcard-btn flex-grow-1">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
            </a>`
            :
            `<a href="#" class="btn btn-primary js-subscriptions-modal flex-grow-1">
                <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
                <i class="fa fa-check check-icon d-none" aria-hidden="true"></i>
            </a>`
          }
          
          <a href="#" class="btn btn-secondary js-play-audio-btn">
            <i class="fa fa-volume-up" aria-hidden="true"></i>
          </a>
          <a href="#" class="btn btn-secondary js-flag-btn">
            <i class="fa flag-icon" aria-hidden="true"> 🚩 </i>
          </a>
        </div>
      </div>`;

    this.showBottomUpWindow(popoverContent);
    wordElement.classList.add('active');
  }

  showBottomUpWindow(content) {
    if (!this.bottomUpWindow) {
      this.createBottomUpWindow();
    }
    this.bottomUpWindow.querySelector('.bottom-up-content').innerHTML = content;
    this.bottomUpWindow.classList.add('show');
    document.body.classList.add('bottom-up-open');
  }

  createBottomUpWindow() {
    this.bottomUpWindow = document.createElement('div');
    this.bottomUpWindow.className = 'bottom-up-window';
    this.bottomUpWindow.innerHTML = `
      <div class="bottom-up-content"></div>
      <button class="bottom-up-close btn-close">&times;</button>
    `;
    document.body.appendChild(this.bottomUpWindow);

    this.bottomUpWindow.querySelector('.bottom-up-close').addEventListener('click', () => {
      this.hideBottomUpWindow();
    });
  }

  hideBottomUpWindow() {
    if (this.bottomUpWindow) {
      this.bottomUpWindow.classList.remove('show');
      document.body.classList.remove('bottom-up-open');
      const activeWords = document.getElementsByClassName('active');
      for (let i = 0; i < activeWords.length; i++) {
        activeWords[i].classList.remove('active');
      }
    }
  }

  disposePopover(element) {
    this.hideBottomUpWindow();
    element.classList.remove('active');
  }

  disposeAllPopovers() {
    this.hideBottomUpWindow();
    const activeWords = document.getElementsByClassName('active');
    for (let i = 0; i < activeWords.length; i++) {
      activeWords[i].classList.remove('active');
    }
  }

  setupEventListeners() {
    super.setupEventListeners();

    // This event listener is specific to the bottom-up popover and should be kept
    document.addEventListener('click', (e) => {
      if (this.bottomUpWindow && !this.bottomUpWindow.contains(e.target) && !e.target.classList.contains('active')) {
        this.hideBottomUpWindow();
      }
    });
  }
}

export default BottomUpPopoverService;
