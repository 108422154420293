document.addEventListener("turbolinks:load", function () {
    $("#language_filter").on("change", function () {
        loadAllEntries();
    });

    $("#searchBar").on("input", function (e) {
        let searchString = e.target.value.trim();
        if (searchString === "") {
            loadAllEntries();
        }
    });

    function loadAllEntries() {
        const urlParams = new URLSearchParams(window.location.search);
        const pageFromUrl = urlParams.get('page') || 1;

        $.ajax({
            url: "/langua/dictionaries",
            method: "GET",
            dataType: "json",
            data: {
                sort_by: $("#sort_by").val(),
                language_filter: $("#language_filter").val(),
                page: pageFromUrl
            },
            success: function (data) {
                console.log("The data at this point is", data);
                updateTable(data.dictionaries);
            },
            error: function (xhr, textStatus, errorThrown) {
                console.error(errorThrown);
            }
        });
    }

    const dictionaryEl = document.getElementById('dictionary');
    if (dictionaryEl !== null) {
        loadAllEntries();
        $("#searchBar").on("input", function (e) {
            let searchString = e.target.value.trim();
            if (searchString === "") {
                loadAllEntries();
            }
        });

        $("#searchBar").on("keypress", function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
                let searchString = e.target.value.trim().toLowerCase();
                if (searchString === "") {
                    loadAllEntries();
                } else {
                    performSearch(searchString);
                }
            }
        });

        $("#searchButton").on("click", function (e) {
            e.preventDefault();
            let searchString = $("#searchBar").val().trim();
            if (searchString === "") {
                loadAllEntries();
            } else {
                performSearch(searchString);
            }
        });


        $("#dictionariesTable").on("keydown", ".editable-cell", function (e) {
            let editedCell = $(this);
            let newValue = editedCell.text().trim();
            let dictionaryId = editedCell.closest("tr").data("dictionary-id");
            if (e.key === 'Enter') {
                e.preventDefault();

                if (newValue !== "") {
                    updateTranslation(dictionaryId, newValue);
                }

                editedCell.blur();
            }
        });

        $("#dictionariesTable").on("blur", ".editable-cell", function () {
            let editedCell = $(this);
            editedCell.attr("contenteditable", "false");
        });


        $("#sort_by").on("change", function () {
            loadAllEntries();
        });

        let languageMapping = {
            'es': 'Spanish',
            'fr': 'French',
            'en': 'English'
        }

        function getLanguageName(languageCode) {
            return languageMapping[languageCode] || languageCode;
        }

        function formatDate(dateString) {
            if (! dateString) {
                return '';
            }
            let date = new Date(dateString);
            let options = {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric'
            };
            let formatter = new Intl.DateTimeFormat('en-US', options);
            return formatter.format(date);
        }

        function updateTable(data) {
            let tableBody = $("#dictionariesTable");
            tableBody.empty();

            $.each(data, function (index, dictionary) {
                let row = tableBody.find("tr[data-dictionary-id='" + dictionary.id + "']");
                let attributes = dictionary
                if (row.length === 0) {
                    row = $("<tr>").attr("data-dictionary-id", dictionary.id);
                    row.append(createNonEditableCell(index + 1));
                    row.append(createNonEditableCell(attributes["word_data"]["word"]));
                    row.append(createEditableCell(attributes.translation));
                    row.append(createNonEditableCell(getLanguageName(attributes["source_language"])));
                    row.append(createNonEditableCell(attributes.status).addClass('status-cell'));
                    row.append(createNonEditableCell(formatDate(attributes["last_flagged"])));
                    row.append(createNonEditableCell(formatDate(attributes["last_updated"])).addClass('last-updated'));
                    if (attributes["word_data"]) {
                        row.append(createNonEditableCell(attributes["context_sentence"]));
                        row.append(createNonEditableCell(attributes["word_data"]['translated_sentence']));
                        row.append(createWordDataCell(attributes['word_data']['word_data']));
                    } else {
                        row.append(createNonEditableCell(''));
                        row.append(createNonEditableCell(''));
                        row.append(createNonEditableCell(''));
                    } tableBody.append(row);
                } else {
                    row.find(".editable-cell").each(function () {
                        $(this).text(attributes[$(this).data("column")]);
                    });
                    row.find("[data-column='status']").text(attributes.status);
                }
            });
        }


        function createEditableCell(content) {
            let cell = $("<td>").addClass("editable-cell");
            cell.attr("contenteditable", "true");
            let paragraph = $("<p>").html(content);
            cell.append(paragraph);

            cell.on('blur', function () {
            let newText = cell.text();
            cell.empty();
            let newParagraph = $("<p>").html(newText);
            cell.append(newParagraph);
                cell.attr('contenteditable', "true");
            });

            return cell;
        }

        function createNonEditableCell(content) {
            let cell = $("<td>").addClass("non-editable-cell");
            cell.attr("contenteditable", "false");
            let paragraph = $("<p>").html(content);
            cell.append(paragraph);
            return cell;
        }
        function generateHtmlForWordData(wordDataString) {
            const keyValuePairs = wordDataString.match(/"([^"]+)"=>"(.*?)"/g);

            if (! keyValuePairs) {
                return "";
            }
            let htmlResult = "";
            keyValuePairs.forEach(pair => {
                const [key, value] = pair.split("=>").map(item => item.trim());
                htmlResult += `<p><strong>${key}:</strong> ${value}</p>`;
            });

            return htmlResult;
        }


        function createWordDataCell(content) {
            let cell = $("<td>").addClass("non-editable-cell");
            cell.attr("contenteditable", "false");
            let paragraph = generateHtmlForWordData(content);
            console.log()
            cell.append(paragraph);
            return cell;
        }

        function updateTableRow(dictionaryId, newValue) {
            let editedRow = $("#dictionariesTable").find("tr[data-dictionary-id='" + dictionaryId + "']");
            if (editedRow.length > 0) {
                editedRow.find(".editable-cell[data-column='translation']").text(newValue);
            }
        }

        function updateTranslation(dictionaryId, newValue) {
            $.ajax({
                url: `/langua/dictionaries/${dictionaryId}/update_translation`,
                method: "PATCH",
                data: {
                    id: dictionaryId,
                    new_translation: newValue
                },
                success: function (response) {
                    if (response.success) {
                        updateTableRow(dictionaryId, newValue);
                        let editedRow = $("#dictionariesTable").find("tr[data-dictionary-id='" + dictionaryId + "']");
                        if (editedRow.length > 0) {
                            editedRow.find(".status-cell").text(response.updated_status);
                            editedRow.find('.last-updated').text(formatDate(response.last_updated));
                        }
                    } else {
                        console.error(response.errors);
                    }
                },
                error: function (xhr, textStatus, errorThrown) {
                    console.error(errorThrown);
                }
            });
        }

        function performSearch(query) {

            $.ajax({
                url: "/langua/dictionaries/search",
                method: "GET",
                data: {
                    query: query
                },
                dataType: "json",
                success: function (data) {
                    console.log("The data at this point is", data)
                    updateTable(data.dictionaries);

                },
                error: function (xhr, textStatus, errorThrown) {
                    console.error(errorThrown);
                }
            });
        }

    }


});
