class AudioSync {
  constructor(playerService, transcriptContainer, transcriptType, isMobileDevice) {
    this.playerService = playerService;
    this.transcriptContainer = transcriptContainer;
    this.transcriptType = transcriptType;
    this.activeWord = null;
    this.activeSentence = null;
    this.animationFrameId = null;
    this.isMobileDevice = isMobileDevice;
    this.challenge = false;

    // Cache DOM queries
    this.words = document.querySelectorAll('.word');
    this.sentences = document.querySelectorAll('.unactive-sentence');
  }

  setChallenge(challenge) {
    this.challenge = challenge;
  }

  updateActiveSentence(currentTime) {
    for (let sentence of sentences) {
      const sentenceStartTime = parseFloat(sentence.dataset.startTime);
      const sentenceEndTime = parseFloat(sentence.dataset.endTime);

      if (currentTime >= sentenceStartTime && currentTime <= sentenceEndTime) {
        if (sentence.classList.contains('active-sentence')) {
          return;
        }

        if (this.activeSentence) {
          this.activeSentence.classList.replace('active-sentence', 'unactive-sentence');
          const activeWords = this.activeSentence.querySelectorAll('.word.active-sentence-challenge');
          activeWords.forEach(word => word.classList.remove('active-sentence-challenge'));
        }

        this.activeSentence = sentence;
        sentence.classList.add('active-sentence');

        if (this.challenge) {
          const sentenceWords = this.activeSentence.querySelectorAll('.word');
          sentenceWords.forEach(word => word.classList.add('active-sentence-challenge'));
        }

        if (!this.isElementOutOfView(this.activeSentence)) {
          this.transcriptContainer.scroll({ top: (this.activeSentence.offsetTop - this.transcriptContainer.offsetTop) - 50, behavior: "smooth" });
        }
        break;
      }
    }
  }

  updateActiveWord(currentTime) {
    const activeSentence = this.getActiveSentence(currentTime);

    if (activeSentence) {
      let wordFound = this.checkWordsInSentence(activeSentence, currentTime);

      if (!wordFound) {
        const nextSentence = activeSentence.nextElementSibling;

        if (nextSentence && nextSentence.classList.contains('unactive-sentence')) {
          this.checkWordsInSentence(nextSentence, currentTime);
        }
      }
    }
  }

  getActiveSentence(currentTime) {
    const sentences = document.querySelectorAll('.unactive-sentence, .active-sentence');
    let activeSentence = null;

    for (let sentence of sentences) {
      const sentenceStartTime = parseFloat(sentence.dataset.startTime);
      const sentenceEndTime = parseFloat(sentence.dataset.endTime);

      if (currentTime >= sentenceStartTime && currentTime <= sentenceEndTime) {
        activeSentence = sentence;
        break;
      }
    }

    return activeSentence;
  }

  checkWordsInSentence(sentenceElement, currentTime) {
    const words = sentenceElement.querySelectorAll('.word');
    let wordFound = false;

    for (let word of words) {
      const wordStartTime = parseFloat(word.dataset.startTime);
      const wordEndTime = parseFloat(word.dataset.endTime);

      if (currentTime >= wordStartTime && currentTime <= wordEndTime) {
        if (this.activeWord && this.activeWord.id === word.id) {
          return true;
        }

        if (this.activeWord) {
          this.activeWord.style.fontWeight = "400";
          this.activeWord.style.webkitTextStroke = "0px";

          this.activeWord.classList.remove('active-sentence-challenge');
        }

        if (!this.activeSentence || this.activeSentence.id !== word.parentElement.id) {
          if (this.activeSentence) {
            this.activeSentence.classList.remove('active-sentence');
            this.activeSentence.classList.add('unactive-sentence');
          }
        }

        this.activeWord = word;
        this.activeWord.style.fontWeight = "500";
        this.activeWord.style.webkitTextStroke = "0.5px";
        this.activeSentence = word.parentElement;

        if (this.challenge && word.classList.contains('challenge')) {
          this.activeWord.classList.add('active-sentence-challenge');
        }
        this.activeSentence.classList.add('active-sentence');

        this.scrollToActiveWord(word);
        wordFound = true;
        break;
      } else {
        word.style.fontWeight = "400";
        word.style.webkitTextStroke = "0px";
        word.style.color = "";  // Reset the color when the word is no longer active.
      }
    }

    return wordFound;
  }

  isElementOutOfView(element) {
    const containerRect = this.transcriptContainer.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    let scrollThreshold;

    if (this.isMobileDevice) {
      scrollThreshold = 250;
    } else {
      scrollThreshold = 150;
    }

    if (
      elementRect.bottom < containerRect.top ||
      elementRect.top > (containerRect.bottom - scrollThreshold) ||
      elementRect.right < containerRect.left ||
      elementRect.left > containerRect.right
    ) {
      return true;
    }

    return false;
  }


  scrollToActiveWord(word) {
    if (word && this.isElementOutOfView(word)) {
      this.transcriptContainer.scroll({
        top: (word.offsetTop - this.transcriptContainer.offsetTop) - 50,
        behavior: "smooth"
      });
    }
  }

  handleTimeUpdate() {
    const currentTime = this.playerService.getCurrentTime();

    if (this.transcriptType === 'vtt') {
      this.updateActiveSentence(currentTime);
    } else {
      this.updateActiveWord(currentTime);
    }

    // Continue the animation loop
    this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
  }

  start() {
    // Start tracking when the audio starts playing
    this.playerService.player.on('play', () => {
      // Kickstart the animation loop
      this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
    });

    // Stop tracking when the audio is paused or ended
    this.playerService.player.on('pause', () => {
      cancelAnimationFrame(this.animationFrameId);
    });

    this.playerService.player.on('ended', () => {
      cancelAnimationFrame(this.animationFrameId);
    });

    // start the animation loop if player already playing
    if (this.playerService.isPlaying()) {
      this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
    }
  }
}

export default AudioSync;
