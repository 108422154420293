import PlyrTracker from './plyr_tracker';
import PodcastsPlyrTracker from "../podcasts/podcasts_plyr_tracker";

class PlayerService {
  constructor(playerElement) {
    this.player = new Plyr(playerElement, {
      clickToPlay: false,
      autoplay: true,
      controls: ['progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen'],
      captions: {active: false, language: 'auto', update: false}
    });

    this.episodeId = playerElement.dataset.podcastEpisodeId;
    this.podcastGuid = playerElement.dataset.podcastGuid;
    this.contentId = playerElement.dataset.contentId;
    this.userId = playerElement.dataset.userId;
    this.userProgress = parseFloat(playerElement.dataset.userProgress);

    this.rewindButton = document.getElementById('rewind');
    this.playButton = document.getElementById('play');
    this.speedButton = document.getElementById('speed');
    this.transcriptContainer = document.getElementById('vtt-container');

    this.init();
    this.setupTracking();
  }

  init() {
    this.player.on('play', () => {
      this.setInitialSpeed();
      this.playButton.innerHTML = '<i class="fa fa-pause"></i>'
    });

    this.player.on('pause', () => {
      this.playButton.innerHTML = '<i class="fa fa-play"></i>'
    })

    this.player.on('ended', () => {
      this.playButton.innerHTML = '<i class="fa fa-play"></i>'
    })

    this.playButton.addEventListener('click', () => {
      this.togglePlayer();
    });

    this.speedButton.addEventListener('click', () => {
      this.setSpeed();
    });

    this.rewindButton.addEventListener('click', () => {
      this.player.rewind(10);
    });


    // if audio content, import nosleep
    if (this.player.isAudio) {
      import(/* webpackChunkName: "nosleep" */ 'nosleep.js').then(module => {
        const NoSleep = module.default;
        const noSleep = new NoSleep();

        // To enable wake lock:
        // // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
        document.addEventListener('click', function enableNoSleep() {
          document.removeEventListener('click', enableNoSleep, false);
          noSleep.enable();
        }, false);
      });
    }
  }

  setupTracking() {
    if (this.userId && this.contentId) {
      this.player.on('ready', () => {
        if (this.player.isVideo) {
          this.setCurrentTime(this.userProgress);
          this.scrollToCurrentTime(this.userProgress);
        } else if (this.player.isAudio) {
          this.player.once('canplay', () => {
            this.setCurrentTime(this.userProgress);
            this.scrollToCurrentTime(this.userProgress);
          });
        }
        this.player.muted = false;
        new PlyrTracker(this.player, '/langua/content_events', this.contentId);
      });
    } else if (this.userId && this.podcastGuid && this.episodeId) {
      this.player.on('ready', () => {
        this.player.once('canplay', () => {
          this.setCurrentTime(this.userProgress);
        });
        this.player.muted = false;
        new PodcastsPlyrTracker(this.player, '/langua/podcast_events', this.podcastGuid, this.episodeId);
      });
    }
  }

  pause() {
    this.player.pause();
  }

  play() {
    this.player.play();
  }

  setCurrentTime(time) {
    this.player.currentTime = parseFloat(time);
  }

  getCurrentTime() {
    return this.player.currentTime;
  }

  togglePlayer() {
    if (this.player.playing) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  isPlaying() {
    return this.player.playing;
  }

  setInitialSpeed() {
    if (isNaN(this.player.speed)) {
      this.player.speed = 1;
      this.speedButton.innerHTML = `1x`;
    } else {
      this.speedButton.innerHTML = `${this.player.speed}x`;
    }
  }

  setSpeed() {
    if (this.player.speed === 1) {
      this.speedButton.innerHTML = '0.85x';
      this.player.speed = 0.85;
    } else if (this.player.speed === 0.85) {
      this.speedButton.innerHTML = '0.75x';
      this.player.speed = 0.75;
    } else if (this.player.speed === 0.75) {
      this.speedButton.innerHTML = '1.25x';
      this.player.speed = 1.25;
    } else {
      this.speedButton.innerHTML = '1x';
      this.player.speed = 1;
    }
  }

  // This function finds the transcript segment corresponding to the current video time
  // and scrolls to make it visible.
  scrollToCurrentTime(currentTime) {
    const sentences = document.querySelectorAll('.unactive-sentence, .active-sentence');

    for (let sentence of sentences) {
      const sentenceStartTime = parseFloat(sentence.dataset.startTime);
      const sentenceEndTime = parseFloat(sentence.dataset.endTime);

      if (currentTime >= sentenceStartTime && currentTime <= sentenceEndTime) {
        // Use the logic you have for scrolling the transcript to this sentence.
        // For instance:
        //if (!isElementOutOfView(sentence)) {
        this.transcriptContainer.scroll({
          top: (sentence.offsetTop - this.transcriptContainer.offsetTop) - 50,
          behavior: "smooth"
        });
        //}
        break;  // Exit loop once the correct segment is found
      }
    }
  }
}

export default PlayerService;
