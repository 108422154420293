class CorrectionExplanationService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  fetchExplanation(chatId, chatMessageId, correctionText, messageText, responseText, language, clientProvider, model) {
    return fetch('/langua/correction_explanation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        chat_id: chatId,
        chat_message_id: chatMessageId,
        correction_text: correctionText,
        message_text: messageText,
        response_text: responseText,
        language: language,
        client_provider: clientProvider,
        model: model
      })
    })
    .then(response => response.json())
  }
}

export default CorrectionExplanationService;
