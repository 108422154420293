document.addEventListener("turbolinks:load", function () {
    if (document.getElementById('plus-icon')) {

        $('#plus-icon').on('click', function(e) {
            let sourceLanguage = $('#sourceLanguage').text().toLowerCase()
            let flashcardLanguage = $('#learningLanguage').text().toLowerCase()
            let flashcardFront;
            let flashcardBack;

            if (sourceLanguage === flashcardLanguage) {
                flashcardFront = $('#sourceText').val();
                flashcardBack = $('#translatedText').val();
            } else {
                flashcardFront = $('#translatedText').val();
                flashcardBack = $('#sourceText').val();
            }

            if (flashcardFront !== '' && flashcardBack !== '') {
                const wordsArray = flashcardFront.split(/\s+/);
                let flashcardType = wordsArray.length >= 4 ? 'sentence' : 'word';
                let flashcardData = [{ front: flashcardFront, back: flashcardBack }];

                if (flashcardData.length > 0) {
                    let $flashcardBtn = $('#add-flashcard-btn');
                    let $plusIcon = $flashcardBtn.find('.plus-icon');
                    let $checkIcon = $flashcardBtn.find('.check-icon');

                    fetch('/langua/flashcards/batch', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                        },
                        credentials: 'same-origin',
                        body: JSON.stringify({
                            flashcard: {
                                flashcards: flashcardData,
                                language: flashcardLanguage,
                                flashcardable_id: null,
                                flashcardable_type: null,
                                flashcard_type: flashcardType
                            }
                        })
                    })
                        .then(response => response.json())
                        .then(function (data) {

                            // Animate the plus icon
                            $plusIcon.css({
                                transition : 'transform 0.3s ease-in-out',
                                transform  : 'rotate(45deg) scale(0)'
                            });

                            // Wait for the transition to finish, then display the check icon
                            setTimeout(function() {
                                $plusIcon.hide();

                                $checkIcon.removeClass('d-none');
                                $checkIcon.css({
                                    transition : 'transform 0.3s ease-in-out',
                                    transform  : 'rotate(-45deg) scale(0)',
                                    display    : 'inline-block'
                                });

                                setTimeout(function() {
                                    $checkIcon.css({
                                        transform  : 'rotate(0deg) scale(1)'
                                    });
                                }, 50); // Wait for the check icon to be rendered before starting the transition
                            }, 300); // Matches the duration of the plus icon's transition
                        })
                }
            }
        });
    }
});
