import consumer from ".././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const podcastEpisodeEl = document.getElementById('js-podcast-episode-transcript');

  window.subscribePodcastEpisodeChannel = function(podcastEpisodeId, podcastEpisodeStatus) {
    if (podcastEpisodeStatus !== 'completed') {
      consumer.subscriptions.create({ channel: "PodcastEpisodeChannel", podcast_episode_id: podcastEpisodeId }, {
        received(data) {
          if (data.transcript_status === 'completed') {
            const transcriptContainer = document.getElementById('vtt-container');
            transcriptContainer.classList.remove('podcast-no-transcript', 'pb-5');
            transcriptContainer.classList.add('podcast-transcript');
            transcriptContainer.dataset.transcript = data.transcript_url;
            transcriptContainer.dataset.transcriptType = data.transcript_type;
            transcriptContainer.dataset.language = data.language;
            transcriptContainer.dataset.audioSyncEnabled = data.audio_sync_enabled;
            window.setupTranscript();
          } else if (data.transcript_status === 'failed') {
            const podcastAlertEl = document.getElementById('js-podcast-episode-alert');
            const podcastEpisodeGenerateBtnEl = document.getElementById('js-podcast-episode-generate-btn');
            // display error message
            podcastAlertEl.classList.remove('alert-warning');
            podcastAlertEl.classList.add('alert-danger');
            podcastAlertEl.innerHTML = `There was an error generating the transcript. Please try again later. <br> Error: ${data.error}`;

            // show and enable generate transcript button
            podcastEpisodeGenerateBtnEl.classList.remove('d-none');
            podcastEpisodeGenerateBtnEl.disabled = false;
          }
        }
      });
    }
  }

  if (podcastEpisodeEl) {
    let podcastEpisodeId = podcastEpisodeEl.dataset.podcastEpisodeId;
    let podcastEpisodeStatus = podcastEpisodeEl.dataset.podcastEpisodeStatus;
    window.subscribePodcastEpisodeChannel(podcastEpisodeId, podcastEpisodeStatus);
  }
})
