document.addEventListener("turbolinks:load", function () {
    const flashcardWordElement = document.getElementById('flashcard_front_word');

    if (flashcardWordElement) {
        let phraseExamplesData = {};
        const currentWord = flashcardWordElement && flashcardWordElement.dataset.word;
        const flashcardLanguageElement = document.getElementById('flashcard_word_language');
        const language = flashcardLanguageElement && flashcardLanguageElement.dataset.language;
        const { displayResult, expandCardHeight } = require('./display_result');
        const batchCreateFlashcards = require('./../contents/batch_create_flashcards');

        $('body').on('click', '.js-flashcards-phrase-examples', function(e) {
            e.preventDefault();

            phraseExamples(currentWord);
        });

        $('body').on('click', '.js-flashcard-phrase-examples-btn', function(e) {
            e.preventDefault();

            if (flashcardWordElement !== null) {
                let flashcardID = flashcardWordElement.dataset.flashcardId
                let flashcardType = 'phrase';
                let phraseExamples = Object.values(phraseExamplesData[currentWord].result.phrases);
                let phraseExample;

                if (phraseExamples.length > 0) {
                    phraseExample = phraseExamples[$(this).data('index')];
                } else {
                    phraseExample = phraseExamples[0];
                }

                let flashcardData = [{
                    front: phraseExample.source,
                    back: phraseExample.translation
                }];

                if (flashcardData.length > 0) {
                    batchCreateFlashcards(this, flashcardData, flashcardType, language, flashcardID, 'Flashcard');
                }
            }
        });

        function phraseExamples(word) {
            if (phraseExamplesData[word]) {
                displayResult(language, phraseExamplesData[word].result.examples, "phrases", ".js-phrase-examples-card");
                return;
            }

            $('.js-phrase-examples-card').html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

            expandCardHeight(25)

            fetch('/langua/phrase_examples', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                credentials: 'same-origin',
                body: JSON.stringify({ word: word, language: language })
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.error) {
                        $('.js-phrase-examples-card').html('<span class="d-flex justify-content-center">There was an error finding phrase examples. Please try again later.</span>');
                    } else {
                        if (Object.keys(data.result).length > 0) {
                            phraseExamplesData[word] = data;

                            displayResult(language, data.result.phrases, "phrases", ".js-phrase-examples-card");
                        } else {
                            $(".js-phrase-examples-card")
                                .html(`<span class="d-flex justify-content-center">No phrase examples found.</span>`);
                        }
                    }
                })
        }
    }
});
