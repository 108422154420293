import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

document.addEventListener('turbolinks:load', function() {
  var calendarEl = document.getElementById('availability-calendar');

  if (calendarEl) {
    import(/* webpackChunkName: "moment" */ 'moment').then((momentModule) => {
      import(/* webpackChunkName: "moment-range" */ 'moment-range').then((momentRangeModule) => {

        const moment = momentModule.default;
        const localMoment = momentRangeModule.extendMoment(moment);

        var modalEl = '#availability-modal';
        var modalFormEl = '#availability-override-form';
        var selectedEvent;

        $(modalEl).on('hidden.bs.modal', function (e) {
          //selectedEvent.setProp('title', document.getElementById('availability_override_form_date').value);
          selectedEvent = undefined;
        });

        var calendar = new Calendar(calendarEl, {
          plugins: [ dayGridPlugin ],
          events: '/account/schedule/' + calendarEl.dataset.id + '?availability=true',
          displayEventTime: false,
          eventDisplay: 'list-item',
          eventColor:  'rgba(150,150,150, 0.5)',
          eventBackgroundColor:  'rgba(150,150,150, 0.5)',
          eventDisplay: 'block',
          headerToolbar: {
            left: 'prev',
            center: 'title',
            right: 'next'
          },
          stickyHeaderDates: true,
          firstDay: 1,
          fixedWeekCount: false,
          eventClick: function(info) {
            if (moment(info.event.start) >= moment().startOf('day')) {
              selectedEvent = info.event;
              document.getElementById('override-date').innerHTML = `Hours for ${moment(info.event.start).format('ddd, MMM D, Y')}`;
              document.getElementById('availability-override').innerHTML = moment(info.event.start).format('ddd, MMM D, Y');
              document.getElementById('availability_override_form_date').value = moment(info.event.start).format('YYYY-MM-DD');
              if (info.event.title.indexOf("Closed") > -1) {
                document.getElementById('availability_override_form_value').value = '';
              } else {
                document.getElementById('availability_override_form_value').value = info.event.title.replace(/(\r\n|\n|\r)/gm, " ");
              }
              $(modalEl).modal('show');
            }
          }
        });

        calendar.render();

        $(modalFormEl).on("ajax:success", function (event) {
          $(modalEl).modal('hide');
          var overrideValue = document.getElementById('availability_override_form_value').value;
          selectedEvent.setProp('title', overrideValue == "" ? 'Closed - Enter availability' : overrideValue)
        }).on("ajax:error", function (event) {
          $(modalFormEl).append(`<p class="alert-danger">There was an error saving your availability. Please try again.</p>`);
        })

        var isValidTimeRange = function (timeRange) {
          return timeRange.start !== 'Invalid date' && timeRange.end !== 'Invalid date';
        }

        var convertToTime = function(input) {
          return localMoment(input, ['h:m a', 'H:m', 'hm', 'Hm']);
        }

        var displayTimeExample = function(dayOfTheWeek, startTime, endTime) {
          var timeRange = localMoment.range(startTime, endTime);
          var timeIntervals = Array.from(timeRange.by('minutes', {step: 30})).map(m => m.format('h:mma'));
          var startTimeExamples = timeIntervals.slice(0, 3).join(", ");
          var endTimeExamples = timeIntervals.slice(-3).join(", ");
        }

        var processTimes = function(values) {
          var finalTimes = [];
          $.each(values, function(index, v) {
            var availabilityTimes = v.split("-");

            // format like "930am - 1030 pm"
            if (availabilityTimes.length > 1 && availabilityTimes.length < 3) {

              var timeRange = {};
              var unformattedTimeStart = convertToTime(availabilityTimes[0]);
              var unformattedTimeEnd = convertToTime(availabilityTimes[1]);
              timeRange.start = convertToTime(availabilityTimes[0]).format('h:mma');
              timeRange.end = convertToTime(availabilityTimes[1]).format('h:mma');

              if (isValidTimeRange(timeRange)) {
                finalTimes.push({
                  start: unformattedTimeStart,
                  end: unformattedTimeEnd,
                  value: `${timeRange.start}-${timeRange.end}`
                });
              }
            } else {
              var time = convertToTime(v);
              var finalTime = time.format('h:mma');

              if (finalTime !== "Invalid date") {
                finalTimes.push({start: time, value: finalTime});
              }
            }
          });

          return finalTimes.sort((left, right) => {
            return localMoment(left.start).diff(localMoment(right.start));
          });
        }

        $('.availability-textarea').focus(function() {
          var $el = $(this);
          var $label = $("label[for='"+$el.attr('id')+"']");
          var dayOfTheWeek = $label.text();
          var values = $el.val().split(",");

          var finalFinal = processTimes(values);

          if (finalFinal.length > 0) {
            if (finalFinal[0].end) {
              displayTimeExample(dayOfTheWeek, finalFinal[0].start, finalFinal[0].end);
            } else if (finalFinal[0].start) {
              displayTimeExample(dayOfTheWeek, finalFinal[0].start, finalFinal[0].start);
            }
          }

        });


        $('.availability-textarea').blur(function() {
          var $el = $(this);
          var $label = $("label[for='"+$el.attr('id')+"']");
          var dayOfTheWeek = $label.text();
          var values = $el.val().split(",");

          var finalFinal = processTimes(values);

          // TODO: Display time example for case where there is a single time and a range, e.g., [8:00am, 9:30am - 11:30pm]
          if (finalFinal.length > 0) {
            if (finalFinal[0].end) {
              displayTimeExample(dayOfTheWeek, finalFinal[0].start, finalFinal[0].end);
            } else if (finalFinal[0].start) {
              displayTimeExample(dayOfTheWeek, finalFinal[0].start, finalFinal[0].start);
            }
          }

          $(this).val(finalFinal.map(e => e.value).join(", "));
        });


      });
    });
  }
});
