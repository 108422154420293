class PlyrTracker {
    constructor(plyr, endpoint, contentId, batchSize = 200) {
        this.plyr = plyr;
        this.endpoint = endpoint;
        this.contentId = contentId;
        this.batchSize = batchSize;
        this.eventBuffer = [];

        this.setupEventListeners();
    }

    setupEventListeners() {
        const events = ['pause', 'timeupdate', 'ended'];
        let lastTime = 0; // Track the last playback position
        let totalWatchedTime = 0; // Track total amount of video watched by user

        events.forEach(event => {
            this.plyr.on(event, (e) => {
                if (e.type === 'pause' || e.type === 'ended') {
                    this.sendEvents();
                } else {
                    this.addToBuffer({
                        content_id: this.contentId,
                        event_name: e.type,
                        event_data: {
                            current_time: this.plyr.currentTime,
                        },
                    });

                    if (this.eventBuffer.length >= this.batchSize) {
                        this.sendEvents();
                    }

                    ({ totalWatchedTime, lastTime } = this.trackWatchedTime(totalWatchedTime, lastTime));
                }
            });
        });
    }

    addToBuffer(event) {
        this.eventBuffer.push(event);
    }

    async sendEvents() {
      if (this.eventBuffer.length > 0) {
        event = this.eventBuffer[this.eventBuffer.length - 1]

        this.eventBuffer = [];

        const response = await fetch(this.endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          body: JSON.stringify({ content_events: event })
        });
      }
    }

    trackWatchedTime(totalWatchedTime, lastTime, type) {
        if (totalWatchedTime < 120) {
            const currentTime = this.plyr.currentTime;
            const timeDifference = currentTime - lastTime;

            // Assuming a small time difference indicates continuous play
            if (timeDifference > 0 && timeDifference < 2) {
                totalWatchedTime += timeDifference;

                if (totalWatchedTime >= 120) {
                    this.sendTimeTrackingEvent()
                }
            }

            lastTime = currentTime
        }

        return { totalWatchedTime: totalWatchedTime, lastTime: lastTime };
    }

    async sendTimeTrackingEvent() {
        const response = await fetch('/langua/user_action_events', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            body: JSON.stringify({ event_data: { event_name: 'content_viewed', data: { content_id: this.contentId } } })
        });
    }
}

export default PlyrTracker;
