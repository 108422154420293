import consumer from "../.././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const bookingConfirmationEl = document.getElementById('booking-confirmation');

  if (bookingConfirmationEl) {
    let bookingId = bookingConfirmationEl.dataset.id
    let bookingStatus = bookingConfirmationEl.dataset.bookingStatus

    if (bookingStatus == 'processing') {
      let refreshTimeout = setTimeout(() => {
        window.location.reload();
      }, 10000);

      consumer.subscriptions.create({ channel: "BookingChannel", booking_id: bookingId }, {
        received(data) {
          clearTimeout(refreshTimeout);

          if (data.status == 'succeeded') {
            if (data.existing_student) {
              window.location.href = `/account/schedule_lessons/${data.tutor_id}/edit?booking_id=${bookingId}&duration=${data.duration}`;
            } else {
              window.location.reload();
            }
          } else {
            window.location.reload();
          }
        }
      });
    }
  }
})
