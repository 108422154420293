document.addEventListener("turbolinks:load", function () {
  const formEl = document.querySelector(".js-vocab-ai-form");

  if (formEl) {
    const wordSelectionOption = document.getElementById('number_of_words');
    const languageOption = document.getElementById('language');
    const voiceOption = document.getElementById('voice');
    const flashcardCheckboxesContainer = document.getElementById('flashcard-checkboxes');
    const checkboxes = document.querySelectorAll('.flashcard-checkbox');
    const maxSelection = 24;
    let selectedCount = 0;
    const textOnlyLanguages = JSON.parse(formEl.dataset.textOnlyLanguages);
    // when form language changes
    // if language selected is spanglish or franglais
    // hide voice input

    checkActionPreconditions(selectedCount);

    languageOption.addEventListener('change', function() {
      checkActionPreconditions(selectedCount);
    });

    voiceOption.addEventListener('change', function() {
      checkActionPreconditions(selectedCount);
    });

    wordSelectionOption.addEventListener('change', function() {
      if (this.value === '-1') {
        flashcardCheckboxesContainer.style.display = 'block';
      } else {
        flashcardCheckboxesContainer.style.display = 'none';
      }

      checkActionPreconditions(selectedCount);
    });

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', function() {
        if (this.checked) {
          selectedCount++;
        } else {
          selectedCount--;
        }

        if (selectedCount > maxSelection) {
          alert("You've hit your word limit.");
          this.checked = false;
          selectedCount--;
        }

        checkActionPreconditions(selectedCount);
      });
    });

    formEl.querySelector("#language").addEventListener("change", function (e) {
      const language = e.target.value;
      const voiceInputEl = formEl.querySelector("#voice");

      // if language is text only
      if (textOnlyLanguages.includes(language) || language.includes("text")) {
        voiceInputEl.classList.add("d-none");

        checkActionPreconditions(selectedCount);
      } else {
        voiceInputEl.classList.remove("d-none");

        checkActionPreconditions(selectedCount);
      }
    });
  }

  function checkActionPreconditions(selectedCount) {
    const wordSelectionOption = document.getElementById('number_of_words');
    const languageOption = document.getElementById('language');
    const voiceOption = document.getElementById('voice');
    const submitButton = document.getElementById('submit-button');

    if (wordSelectionOption.value == '' || languageOption.value == '' || (!voiceOption.classList.contains('d-none') && voiceOption.value == '')) {
      submitButton.disabled = true;
    } else if (wordSelectionOption.value == '-1') {
      submitButton.disabled = selectedCount === 0;
    } else {
      submitButton.disabled = false;
    }
  }
});
