class WordTimingsService {

  constructor() {
    this.wordTimings = null;
  }

  reset() {
    this.wordTimings = null;
  }

  processNormalizedAlignment(normalizedAlignment) {
    let wordTimings = null;

    let wordIndex = 0;
    let wordStartTime = normalizedAlignment.charStartTimesMs[0];
    let wordDuration = 0;

    let words = [];
    let wordStartTimesMs = [];
    let wordDurationsMs = [];

    normalizedAlignment.chars.forEach((char, i) => {
      wordDuration += normalizedAlignment.charDurationsMs[i];

      if ([' ', ',', '.', null].includes(char)) {
        const word = normalizedAlignment.chars.slice(wordIndex, i).join('');
        if (word !== '') {
          words.push(word);
          wordStartTimesMs.push(wordStartTime);
          wordDurationsMs.push(wordDuration);
        }

        wordIndex = i + 1;

        if (i + 1 < normalizedAlignment.charStartTimesMs.length) {
          wordStartTime = normalizedAlignment.charStartTimesMs[i + 1];
        }

        wordDuration = 0;
      }
    });

    const punctuationRegex = /[^\w\s]/g;
    const filteredWords = words.map(word => word.replace(punctuationRegex, ''));

    if (this.wordTimings === undefined || this.wordTimings === null) {
      wordTimings = {
        words: filteredWords,
        word_start_times_ms: wordStartTimesMs,
        word_durations_ms: wordDurationsMs
      };
          } else {
      const previousLastWordStartTimeMs = this.wordTimings.word_start_times_ms.slice(-1)[0];
      const previousWordDurationMs = this.wordTimings.word_durations_ms.slice(-1)[0];

      const offset = previousLastWordStartTimeMs + previousWordDurationMs;

      wordStartTimesMs = wordStartTimesMs.map(time => time + offset);

      wordTimings = {
        words: this.wordTimings.words.concat(filteredWords),
        word_start_times_ms: this.wordTimings.word_start_times_ms.concat(wordStartTimesMs),
        word_durations_ms: this.wordTimings.word_durations_ms.concat(wordDurationsMs)
      };
          }

    this.wordTimings = wordTimings;

    return wordTimings;
  }
}

export default WordTimingsService;
