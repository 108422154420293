class SlowAudioService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  fetch(contentType, contentId, audioUrl, chatMessageId = null) {
    return fetch('/langua/slow_audio', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        content_type: contentType,
        content_id: contentId,
        audio_url: audioUrl,
        speed: 0.75,
        chat_message_id: chatMessageId
    })
    })
    .then(response => response.json())
  }
}

export default SlowAudioService
