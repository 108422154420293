function batchCreateFlashcards(el, flashcardData, flashcardType, language, flashcardableId, contentType) {
    let $btn = $(el);
    let $plusIcon = $btn.find('.plus-icon');
    let $checkIcon = $btn.find('.check-icon');

    fetch('/langua/flashcards/batch', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        credentials: 'same-origin',
        body: JSON.stringify({
            flashcard: {
                flashcards: flashcardData,
                language: language,
                flashcardable_id: flashcardableId,
                flashcardable_type: contentType,
                flashcard_type: flashcardType
            }
        })
    })
        .then(response => response.json())
        .then(function (data) {

            // Animate the plus icon
            $plusIcon.css({
                transition : 'transform 0.3s ease-in-out',
                transform  : 'rotate(45deg) scale(0)'
            });

            // Wait for the transition to finish, then display the check icon
            setTimeout(function() {
                $plusIcon.hide();

                $checkIcon.removeClass('d-none');
                $checkIcon.css({
                    transition : 'transform 0.3s ease-in-out',
                    transform  : 'rotate(-45deg) scale(0)',
                    display    : 'inline-block'
                });

                setTimeout(function() {
                    $checkIcon.css({
                        transform  : 'rotate(0deg) scale(1)'
                    });
                }, 50); // Wait for the check icon to be rendered before starting the transition
            }, 300); // Matches the duration of the plus icon's transition
        })
}

module.exports = batchCreateFlashcards;
