function playAudioWord(el, text, language, soundPlaying, soundElClass = '.fa-volume-up', otherOptions={}) {
  if (soundPlaying) {
    return;
  }

  let soundEl = el.querySelector(soundElClass);
  if (soundEl) {
    soundEl.classList.add('spin-loading');
  }

  return import(/* webpackChunkName: "howler" */ 'howler').then((howlerModule) => {
    return fetch('/langua/stream_text_to_speech', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      credentials: 'same-origin',
      body: JSON.stringify({text: text, language: language, ...otherOptions})
    })
      .then(response => response.blob())
      .then(function (blob) {
        const blobUrl = URL.createObjectURL(blob);
        const sound = new Howl({
          src: [blobUrl],
          format: ['mp3'],
          onplay: function () {
            soundPlaying = true;

            if (soundEl) {
              soundEl.classList.add('pulse');
            }
          },
          onend: function () {
            soundPlaying = false;
            if (soundEl) {
              soundEl.classList.remove('pulse');
            }
          }
        });

        if (soundEl) {
          soundEl.classList.remove('spin-loading');
        }

        sound.play();
      })
      .catch(error => {
        soundPlaying = false;

        if (soundEl) {
          soundEl.classList.remove('spin-loading');
        }
      });
  })
}

module.exports = playAudioWord;
