document.addEventListener("turbolinks:load", function () {
  if (document.getElementById('play_audio_translator')) {
    let soundPlaying = false;

    $('#play_audio_translator').on('click', function(e) {
        let sourceLanguage = $('#sourceLanguage').text().toLowerCase()
        let learningLanguage = $('#learningLanguage').text().toLowerCase();
        let text;

        if (sourceLanguage === learningLanguage) {
            text = $('#sourceText').val();
        } else {
            text = $('#translatedText').val();
        }

      e.preventDefault();

      playAudioWord(this, text, learningLanguage, soundPlaying)
    });

    let playAudioWord = require('./../contents/play_audio_word');
  }
});
