class ChatFeedbackModalService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
    this.initializeElements();
  }

  initializeElements() {
    this.translatorModalEl = $('#js-chat-feedback-modal');
  }

  openModal() {
    let chatId = this.translatorModalEl.data('chatId');

    this.fetchUserMessagesCount(chatId);
  }

  fetchUserMessagesCount(chatId) {
    $.ajax({
      url: `/account/chats/${chatId}/user_messages_count`,
      method: 'GET',
      success: (data) => {
        let userMessagesCount = data.user_messages_count;

        this.updateModalContent(userMessagesCount, chatId);

        this.translatorModalEl.modal('show');
      }
    });
  }

  updateModalContent(userMessagesCount, chatId) {
    let feedbackMessage = '';
    let feedbackReportButton = '';
    let transcriptButton = '';
    let chatPoints = userMessagesCount * 2;

    if (userMessagesCount > 5) {
      feedbackMessage = `<div class="text-center p-2 pb-4" id="feedback-modal-message">
                            <span style="font-size: 1rem">You've earned <strong>${chatPoints} points</strong> in this chat, nice work! You can end the chat below (feel free to come back to it later). Or you can get feedback on what you've said.</span>
                         </div>`;
      transcriptButton = '<div class="mb-3" id="get-transcript-button"><a href="/account/chats/' + chatId + '/transcript.pdf" class="btn btn-get-chat-transcript btn-block" target="_blank">Download transcript (PDF)</a></div>';
      feedbackReportButton = '<div class="mb-3" id="get-feedback-button"><a href="/account/chats/' + chatId + '/feedback" class="btn btn-get-chat-feedback btn-block">Get my feedback report</a></div>';
    } else {
      feedbackMessage = `<div class="text-center p-2 pb-4" id="feedback-modal-message">
                            <span style="font-size: 1rem">You\'ve earned <strong>${chatPoints} points</strong> in this chat, nice work! Ready to end the chat? You can always come back to it later. Want a feedback report or transcript covering today\'s chat? You’ll see those options here after you’ve replied at least 6 times.</span>
                         </div>`;
      transcriptButton = '<div class="mb-3" id="get-transcript-button"></div>'
      feedbackReportButton = '<div class="mb-3" id="get-feedback-button"></div>'
    }

    $('#feedback-modal-message').replaceWith(feedbackMessage);
    $('#get-transcript-button').replaceWith(transcriptButton);
    $('#get-feedback-button').replaceWith(feedbackReportButton);
  }
}

export default ChatFeedbackModalService;
