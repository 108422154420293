import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function() {
  var searchForm = document.getElementById('admin-tutor-search');

  if (searchForm) {

    var searchInput = document.getElementById('js-search-tutors');
    var delayTimer;

    searchInput.addEventListener("input", function(event) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function() {
        console.log('search input changed in timeout');
        handleSubmitForm();
      }, 1000);
    });

    searchInput.addEventListener("keypress", function(event) {
      if (event.key === 'Enter') {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function() {
          console.log('search input changed in timeout');
          handleSubmitForm();
        }, 1000);
      }
    });

    function handleSubmitForm() {
      var $searchForm = $(searchForm);
      var formAction = $searchForm.attr('action');
      const formData = $searchForm.serialize();

      console.log('submitting form')
      Rails.fire(searchForm, 'submit');

      var url = formAction + "?" + formData;

      history.replaceState(history.state, "", formAction + "?" + formData);
    }

  }
});
