import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function() {
  const copyEmailEl = '.js-copy-email';
  let copyEmailCollection = document.querySelectorAll(copyEmailEl);

  if (copyEmailCollection.length > 0) {
    import(/* webpackChunkName: "clipboard" */ 'clipboard').then((clipboardModule) => {

      const Clipboard = clipboardModule.default;
      var clipboard = new Clipboard(copyEmailEl);

      clipboard.on('success', function(e) {
        var clipboardEl = e.trigger;
        $(clipboardEl).tooltip({
          title: 'Copied!'
        })

        $(clipboardEl).tooltip('show');

        setTimeout(function() {
          $(clipboardEl).tooltip('dispose')
        }, 1000);

        e.clearSelection();
      });

      clipboard.on('error', function(e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
      });
    });
  }

  var searchForm = document.getElementById('admin-student-search');

  if (searchForm) {
    var searchInput = document.getElementById('js-search-students');
    var delayTimer;

    searchInput.addEventListener("input", function(event) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function() {
        handleSubmitForm();
      }, 1000);
    });

    searchInput.addEventListener("keypress", function(event) {
      if (event.key === 'Enter') {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function() {
          handleSubmitForm();
        }, 1000);
      }
    });

    function handleSubmitForm() {
      var $searchForm = $(searchForm);
      var formAction = $searchForm.attr('action');
      const formData = $searchForm.serialize();

      console.log('submitting form')
      Rails.fire(searchForm, 'submit');

      var url = formAction + "?" + formData;

      history.replaceState(history.state, "", formAction + "?" + formData);
    }

  }
});
