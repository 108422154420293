 class PodcastsPlyrTracker {
    constructor(plyr, endpoint, podcastGuid, episodeId, batchSize = 200) {
        this.plyr = plyr;
        this.endpoint = endpoint;
        this.podcastGuid = podcastGuid;
        this.episodeId = episodeId;
        this.batchSize = batchSize;
        this.eventBuffer = [];

        this.setupEventListeners();
    }

    setupEventListeners() {
        const events = ['pause', 'timeupdate', 'ended'];
        let lastTime = 0; // Track the last playback position
        let totalListenedTime = 0; // Track total amount of episode listen by user

        events.forEach(event => {
            this.plyr.on(event, (e) => {
                if (e.type === 'pause' || e.type === 'ended') {
                    this.sendEvents();
                } else {
                    this.addToBuffer({
                        podcast_guid: this.podcastGuid,
                        episode_id: this.episodeId,
                        event_name: e.type,
                        event_data: {
                            current_time: this.plyr.currentTime,
                        },
                    });

                    if (this.eventBuffer.length >= this.batchSize) {
                        this.sendEvents();
                    }

                    ({ totalListenedTime, lastTime } = this.trackListenedTime(totalListenedTime, lastTime));
                }
            });
        });
    }

    addToBuffer(event) {
        this.eventBuffer.push(event);
    }

    async sendEvents() {
      if (this.eventBuffer.length > 0) {
        event = this.eventBuffer[this.eventBuffer.length - 1]
        this.eventBuffer = [];

        const response = await fetch(this.endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          body: JSON.stringify({ podcast_events: event })
        });
      }
    }

     trackListenedTime(totalListenedTime, lastTime, type) {
         if (totalListenedTime < 120) {
             const currentTime = this.plyr.currentTime;
             const timeDifference = currentTime - lastTime;

             // Assuming a small time difference indicates continuous play
             if (timeDifference > 0 && timeDifference < 2) {
                 totalListenedTime += timeDifference;

                 if (totalListenedTime >= 120) {
                     this.sendTimeTrackingEvent()
                 }
             }

             lastTime = currentTime
         }

         return { totalListenedTime: totalListenedTime, lastTime: lastTime };
     }

     async sendTimeTrackingEvent() {
         const response = await fetch('/langua/user_action_events', {
             method: 'POST',
             headers: {
                 'Content-Type': 'application/json',
                 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
             },
             body: JSON.stringify({ event_data: { event_name: 'podcast_listened', data: { podcast_guid: this.podcastGuid, episode_id: this.episodeId} } })
         });
     }
}

export default PodcastsPlyrTracker;
