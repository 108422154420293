document.addEventListener('turbolinks:load', function() {
  var editLessonsEl = document.getElementById('edit-lessons');
  var deleteSelectedBtn = document.getElementById('js-delete-selected');
  var extendExpiryBtn = document.getElementById('js-extend-expiry');
  var modalEl = '#extend-expiry-modal';

  if (editLessonsEl) {

    // Enable / disable extend and delete buttons
    var processChecked = function() {
      var checked = document.querySelectorAll('.js-lesson-ids:checked');

      if (checked.length === 0) {
        deleteSelectedBtn.disabled = true;
        deleteSelectedBtn.classList.add('disabled');
        extendExpiryBtn.disabled = true;
        extendExpiryBtn.classList.add('disabled');
      } else {
        deleteSelectedBtn.disabled = false;
        deleteSelectedBtn.classList.remove('disabled');
        extendExpiryBtn.disabled = false;
        extendExpiryBtn.classList.remove('disabled');
      }
    }

    // When select all checkbox is clicked
    $('#select-all').click(function(event) {
      var selected = this.checked;
      $(':checkbox').each(function () {
        this.checked = selected;
      });

      processChecked();
    });

    // Disable / enable buttons when checkbox is checked / unchecked
    $('.js-lesson-ids').each(function(index, checkbox) {
      $(checkbox).change(function(evt) {
        processChecked();
      })
    });

    // Update extend expiry modal when button clicked
    $('#js-extend-expiry').click(function(event) {
      if (!extendExpiryBtn.disabled) {
        var lessonIds = Array.from($(".js-lesson-ids:checked"), a => a.value);

        document.getElementById('lesson_ids').value = JSON.stringify(lessonIds);
        document.getElementById('js-extended-lessons').innerHTML = `Extend expiry for ${lessonIds.length} lesson(s)`;

        $(modalEl).modal('show');
      }
    });
  }
});
