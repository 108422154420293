import consumer from ".././channels/consumer"

document.addEventListener('turbolinks:load', function() {
  const contentEl = document.getElementById('js-content-container');

  if (contentEl) {
    let contentId = contentEl.dataset.contentId
    let contentStatus = contentEl.dataset.transcriptStatus
    let userContent = contentEl.dataset.userContent

    if (userContent == 'true' && contentStatus !== 'ready') {
      consumer.subscriptions.create({ channel: "ContentChannel", content_id: contentId }, {
        received(data) {
          if (data.transcript_status == 'ready' || data.transcript_status == 'failed') {
            window.location.reload();
          }
        }
      });
    }
  }
})
