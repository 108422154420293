import TranslationService from './translation_service';
import PopoverService from './popover_service';
import BottomPopoverService from './bottom_popover_service';
import phraseSelectionAlert from "./phrase_selection_alert";

document.addEventListener("turbolinks:load", function () {
  const storyElement = document.getElementById('story-container');

  if (storyElement) {
    let currentSentence;
    let currentWordEl;
    let currentSentenceEl;
    let includeContextSentence = false;
    let translationProvider = 'deepl';
    let isSelecting = false;
    let isDragging = false;

    const contentId = storyElement.dataset.contentId;
    const contentType = storyElement.dataset.contentType;
    const language = storyElement.dataset.language;

    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    const premiumAccount = storyElement.dataset.premiumAccount;

    const translationService = new TranslationService(translationProvider, language, includeContextSentence, csrfToken);

    const popoverService = new BottomPopoverService(translationService, language, contentId, contentType, csrfToken, premiumAccount);

    $('body').on('click', '.js-translatable-text.word, .highlighted-word', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const currentActive = document.getElementsByClassName('active');
      const hasCurrentActive = currentActive.length > 0;
      if (hasCurrentActive) {
        popoverService.disposePopover(currentActive[0]);
        return;
      }

      currentWordEl = this;
      currentSentenceEl = currentWordEl.parentNode;

      if (currentSentenceEl !== undefined && currentSentenceEl.dataset.text !== undefined) {
        currentSentence = currentSentenceEl.dataset.text;
      } else {
        currentSentence = getSentenceOfWord(currentWordEl);
      }

      document.querySelectorAll('.js-translatable-text.active, .highlighted-word.active').forEach(el => {
        popoverService.disposePopover(el)
      });

      popoverService.showLoadingPopover(currentWordEl);
      translationService.translateWord(currentWordEl, currentSentence, (translatedData) => {
        // Callback function to execute after translation is done

        // Show the loading popover
        popoverService.updatePopoverContent(currentWordEl, translatedData);
        currentWordEl.classList.remove('loading');

      });
    });

    $('body').on('mousedown', '.js-translatable-text, .text-only', function(e) {
      isSelecting = true;
    });

    $('body').on('mousemove', function(e) {
      if (isSelecting) {
        isDragging = true;
      }
    });

    $('body').on('mouseup', function(e) {
      if (isDragging) {
        isSelecting = false;
        isDragging = false;

        e.stopPropagation();

        let currentPhrase = window.getSelection().toString();
        let selectedTextLength = currentPhrase.replace(/\s+/g, '').length;

        if (selectedTextLength > 0 && selectedTextLength <= 70) {
          e.preventDefault();

          let currentWordEl = e.target;

          popoverService.showLoadingPopover(currentWordEl);
          translationService.translateSentence(currentPhrase, (translatedData) => {
            // Callback function to execute after translation is done

            // Show the loading popover
            popoverService.updatePopoverContent(currentWordEl, translatedData, true);
            currentWordEl.classList.remove('loading');
          });
        } else {
          if (selectedTextLength > 70) {
            let phraseSelectionAlert = require('./phrase_selection_alert');

            phraseSelectionAlert();
          }
        }
      } else {
        isSelecting = false;
      }
    });
    
    function cleanSentence(sentence) {
      const cleanups = [
        // Replace multiple spaces with a single space
        [/\s+/g, ' '],

        // Handle punctuation spacing
        [/\s*([,.:;?!»)}\]°"'%‰‱])/g, '$1'],
        [/([«({\[])\s*/g, '$1'],
        [/(\S)\s*([:.;,])/g, '$1$2'],

        // Handle apostrophes
        [/(\S)\s*([''])\s*(\S)/g, '$1$2$3'],

        // Handle quotation marks
        [/"(\S)/g, '" $1'],
        [/(\S)"/g, '$1 "'],
        [/([.!?])\s*"/g, '$1"'],

        // Remove leading punctuation and spaces
        [/^\s*([^\w\s]+\s*)*/, '']
      ];

      return cleanups.reduce((text, [pattern, replacement]) =>
        text.replace(pattern, replacement), sentence).trim();
    }

    function getSentenceOfWord(element) {
      const endOfSentenceRegex = /[.!?。！？។៕။…\u3002\uFF01\uFF1F\u0964\u0965]/;
      const isEndOfSentence = (text) => endOfSentenceRegex.test(text);
      const cleanText = (text) => text.trim().replace(/&nbsp;/g, ' ');

      // Helper function to get the text content of an element
      const getElementText = (el) => {
        if (el.nodeType === Node.ELEMENT_NODE &&
            (el.classList.contains('js-translatable-text') || el.classList.contains('highlighted-word'))) {
          return el.textContent;
        } else if (el.nodeType === Node.TEXT_NODE) {
          return el.textContent;
        }
        return '';
      };
   
      // Function to collect sentence parts
      const collectSentenceParts = (startElement, direction) => {
        let parts = [];
        let current = startElement;
        let reachedSentenceBoundary = false;
   
        while (current && !reachedSentenceBoundary) {
          let text = cleanText(getElementText(current));
         
          if (text) {
            if (direction === 'left') {
              if (isEndOfSentence(text[0])) {
                parts.unshift(text.slice(1));
                reachedSentenceBoundary = true;
              } else {
                parts.unshift(text);
              }
            } else {
              parts.push(text);
              if (isEndOfSentence(text[text.length - 1])) {
                reachedSentenceBoundary = true;
              }
            }
          }

          if (current.nodeType === Node.ELEMENT_NODE && 
              ['P', 'DIV', 'BLOCKQUOTE'].includes(current.tagName)) {
            reachedSentenceBoundary = true;
          }

          current = direction === 'left' ? current.previousSibling : current.nextSibling;
        }

        return parts;
      };

      // Collect parts to the left and right
      const leftParts = collectSentenceParts(element.previousSibling, 'left');
      const rightParts = collectSentenceParts(element.nextSibling, 'right');
 
      // Combine all parts
      const allParts = [...leftParts, cleanText(getElementText(element)), ...rightParts];
 
      // Join the sentence parts and clean up
      let sentence = allParts.join(' ');
      return cleanSentence(sentence);
    }
  }
});
