import AudioPlayer from './audio_player';
import WordTimingsService from './word_timings_service'

class ChatMessageService {
  constructor() {
    this.audioChunks = []; // Array of audio chunks to play
    this.wordTimings;
    this.wordTimingsService = new WordTimingsService();
    this.audioPlayer = new AudioPlayer();
    this.fullMessage = null;
    this.chatMessageElement;
    this.chatElement;
    this.wordElements;
    this.activeWord;
    this.player;
    this.animationFrameId = null;
    this.speedOptions = {
      "slow": 0.8,
      "standard": 1.0,
      "fast": 1.5,
    }
    this.chatMessageId = null;
    this.messageData = null;
    this.textOnly = false;
    this.allAudioReceived = false;
    this.fullMessageReceived = false;
    this.soundPlaying = false;
    this.playButton;
    this.audioPlayerService;
    this.retryCount = 0;
  }

  processAudio(data) {
    if (data.normalizedAlignment !== undefined && data.normalizedAlignment !== null) {
      // TODO: Renable this
      // this.wordTimings = this.wordTimingsService.processNormalizedAlignment(data.normalizedAlignment);
      this.processNormalizedAlignment(data.normalizedAlignment);
    }

    if (data.audioType.includes('mp3')) {
      this.processMp3Audio(data.audio);
    } else if (data.audioType.includes('pcm')) {
      this.processPcmAudio(data.audio);
    }
  }

  processPcmAudio(audioData) {
    // pcm 16000 data
    const sampleRate = 24000;
    const pcmData = window.atob(audioData)

    // Convert the binary string to a Uint8Array
    const byteArray = new Uint8Array(pcmData.length);
    for (let i = 0; i < pcmData.length; i++) {
      byteArray[i] = pcmData.charCodeAt(i);
    }

    // Convert the Uint8Array to an ArrayBuffer
    const arrayBuffer = byteArray.buffer;

    // Create an AudioContext
    const audioContext = this.audioPlayer.audioContext;

    // Create an AudioBuffer
    const audioBuffer = audioContext.createBuffer(1, arrayBuffer.byteLength / 2, sampleRate);

    // Fill the AudioBuffer with the PCM data
    const channelData = audioBuffer.getChannelData(0);
    const dataView = new DataView(arrayBuffer);
    for (let i = 0; i < arrayBuffer.byteLength / 2; i++) {
      // Get the 16-bit sample at the current position, divide by 32768 to normalize to -1.0 - 1.0
      channelData[i] = dataView.getInt16(i * 2, true) / 32768;
    }

    this.audioChunks.push(audioBuffer);
  }

  processMp3Audio(audioData) {
    const mp3Data = window.atob(audioData);
    this.audioChunks.push(mp3Data);
  }

  processNormalizedAlignment(normalizedAlignment) {
    let wordTimings = null;

    let wordIndex = 0;
    let wordStartTime = normalizedAlignment.charStartTimesMs[0];
    let wordDuration = 0;

    let words = [];
    let wordStartTimesMs = [];
    let wordDurationsMs = [];

    normalizedAlignment.chars.forEach((char, i) => {
      wordDuration += normalizedAlignment.charDurationsMs[i];

      if ([' ', ',', '.', null].includes(char)) {
        const word = normalizedAlignment.chars.slice(wordIndex, i).join('');
        if (word !== '') {
          words.push(word);
          wordStartTimesMs.push(wordStartTime);
          wordDurationsMs.push(wordDuration);
        }

        wordIndex = i + 1;

        if (i + 1 < normalizedAlignment.charStartTimesMs.length) {
          wordStartTime = normalizedAlignment.charStartTimesMs[i + 1];
        }

        wordDuration = 0;
      }
    });

    const punctuationRegex = /[^\w\s]/g;
    const filteredWords = words.map(word => word.replace(punctuationRegex, ''));

    if (this.wordTimings === undefined || this.wordTimings === null) {
      wordTimings = {
        words: filteredWords,
        word_start_times_ms: wordStartTimesMs,
        word_durations_ms: wordDurationsMs
      };
          } else {
      const previousLastWordStartTimeMs = this.wordTimings.word_start_times_ms.slice(-1)[0];
      const previousWordDurationMs = this.wordTimings.word_durations_ms.slice(-1)[0];

      const offset = previousLastWordStartTimeMs + previousWordDurationMs;

      wordStartTimesMs = wordStartTimesMs.map(time => time + offset);

      wordTimings = {
        words: this.wordTimings.words.concat(filteredWords),
        word_start_times_ms: this.wordTimings.word_start_times_ms.concat(wordStartTimesMs),
        word_durations_ms: this.wordTimings.word_durations_ms.concat(wordDurationsMs)
      };
          }

    this.wordTimings = wordTimings;
  }

  setupPlayer(url, soundEl, playbackRate = 1.0) {

    this.player = new Howl({
      src: [url],
      format: ['mp3'],
      onplay: () => {
        this.soundPlaying = true;
        if (soundEl) {
          soundEl.classList.add('d-none');
        }
      },
      onend: () => {
        this.soundPlaying = false;
      },
      rate: playbackRate
    });

    this.playButton = soundEl.parentNode.querySelector('.js-pause-chat-audio-btn');

    this.player.on('play', () => {
      this.playButton.classList.remove('d-none')
      this.playButton.innerHTML = '<i class="fa fa-pause"></i>'
    });

    this.player.on('pause', () => {
      this.playButton.innerHTML = '<i class="fa fa-play"></i>'
    })

    this.player.on('end', () => {
      // this.playButton.classList.add('d-none')
      this.playButton.innerHTML = '<i class="fa fa-play"></i>'
    })

    // this.playButton.addEventListener('click', () => {
    //   this.togglePlayer();
    // });
  }

  togglePlayer() {
    if (this.player.playing()) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  reset() {
    this.audioChunks = [];
    this.wordTimings = null;
    this.chatMessageElement = null;

    if (this.activeWord !== null && this.activeWord !== undefined) {
        this.activeWord.style.fontWeight = "400";
        this.activeWord.style.webkitTextStroke = "0px";
        this.activeWord.style.color = "";  // Reset the color when the word is no longer active.
      this.activeWord = null;
    }
    this.activeWord = null;

    // this.player = null;
    this.animationFrameId = null;
    this.chatMessageId = null;
    this.messageData = null;
    this.textOnly = false;
    this.fullMessage = null;
    this.allAudioReceived = false;
    this.fullMessageReceived = false;
    this.soundPlaying = false;
    // this.playButton = null;
  }

  setupSync() {
    this.player.on('play', () => {
      // Kickstart the animation loop
      this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
    });

    // Stop tracking when the audio is paused or ended
    this.player.on('pause', () => {
      cancelAnimationFrame(this.animationFrameId);
    });

    this.player.on('end', () => {
      cancelAnimationFrame(this.animationFrameId);
      this.reset();
    });

    this.player.on('playerror', (error) => {
      console.error('Error playing audio:', error);
      if (this.retryCount < 3) {
        this.retryCount += 1;
        this.player.once('unlock', function() {
          this.player.play();
        });
      }
      cancelAnimationFrame(this.animationFrameId);
      this.reset();
    });

    this.player.on('loaderror', (error) => {
      console.error('Error playing audio:', error);
      cancelAnimationFrame(this.animationFrameId);
      this.reset();
    });
  }

  updateActiveWord(currentTime) {
    if (this.activeWord !== null && this.activeWord !== undefined) {
      const wordStartTime = parseFloat(this.activeWord.dataset.startTime);
      const wordEndTime = parseFloat(this.activeWord.dataset.endTime);
      if (currentTime >= wordStartTime && currentTime <= wordEndTime) {
        return true;
      }
    }

    for (let word of this.wordElements) {
      const wordStartTime = parseFloat(word.dataset.startTime);
      const wordEndTime = parseFloat(word.dataset.endTime);

      if (currentTime >= wordStartTime && currentTime <= wordEndTime) {
        if (this.activeWord && this.activeWord.id === word.id) {
          return true;
        }

        if (this.activeWord !== null && this.activeWord !== undefined) {
          this.activeWord.style.fontWeight = "400";
          this.activeWord.style.webkitTextStroke = "0px";
        }

        this.activeWord = word;
        this.activeWord.style.fontWeight = "500";
        this.activeWord.style.webkitTextStroke = "0.5px";

        break;
      } else {
        word.style.fontWeight = "400";
        word.style.webkitTextStroke = "0px";
        word.style.color = "";  // Reset the color when the word is no longer active.
      }
    }
  }

  handleTimeUpdate() {
    const currentTime = this.player.seek();
    this.updateActiveWord(currentTime);

    // Continue the animation loop
    this.animationFrameId = requestAnimationFrame(this.handleTimeUpdate.bind(this));
  }

  setupWordElements() {
    const wordTimings = this.wordTimings;
    this.wordElements = Array.from(this.chatMessageElement.querySelectorAll('.js-translatable-text.word'));

    for (let i = 0; i < wordTimings.words.length; i++) {
      const startTime = wordTimings.word_start_times_ms[i];
      const duration = wordTimings.word_durations_ms[i];

      const wordElement = this.wordElements[i];
      if (wordElement) {
        wordElement.dataset.startTime = startTime / 1000; // Set data-start-time attribute in seconds
        wordElement.dataset.endTime = (startTime + duration) / 1000; // Set data-end-time attribute in seconds
        wordElement.id = `word-${i}`;
      }
    }
  }

  playAudio(voiceSpeed) {
    const playbackRate = this.speedOptions[voiceSpeed];
    const combinedAudioData = this.audioChunks.join("");
    const byteArray = new Uint8Array(combinedAudioData.length);
    let audioBtn, slowAudioBtn;

    for (let i = 0; i < combinedAudioData.length; i++) {
      byteArray[i] = combinedAudioData.charCodeAt(i);
    }
    const audioBlob = new Blob([byteArray.buffer], { type: 'audio/mp3' });
    const audioUrl = URL.createObjectURL(audioBlob);

    // find closest js-play-chat-audio-btn to chatMessageElement
    if (this.chatMessageElement !== undefined && this.chatMessageElement !== null) {
      audioBtn = this.chatMessageElement.querySelector('.js-play-chat-audio-btn');
      slowAudioBtn = this.chatMessageElement.querySelector('.js-slow-audio-btn');
      if (audioBtn !== undefined && audioBtn !== null) {
        if (this.chatMessageId !== null) {
          const audioKey = `audioUrl_${this.chatMessageId}`;
          localStorage.setItem(audioKey, audioUrl);
          audioBtn.dataset.audioKey = audioKey;
        }
      }
    }

    this.audioChunks = [];
    // TODO: Remove this
    // current sync code - words eventually get out of sync
    // audioBtn.dataset.wordTimings = JSON.stringify(this.wordTimings);
    // this.audioPlayerService.setupWordElements(this.wordTimings, this.chatMessageElement);
    // this.audioPlayerService.setupPlayer(audioUrl, audioBtn, playbackRate);
    // this.audioPlayerService.setupSync();
    // this.audioPlayerService.player.play();
    // return this.audioPlayerService.player;

    // TODO: Remove this
    // Old sync - working better sync
    // this.setupWordElements();
    // this.setupPlayer(audioUrl, audioBtn, playbackRate);
    // this.setupSync();
    // this.player.play();
    // return this.player;

    // Todo: Mix and match approch that syncs better
    audioBtn.dataset.wordTimings = JSON.stringify(this.wordTimings);
    slowAudioBtn.dataset.wordTimings = JSON.stringify(this.wordTimings);
    this.wordElements = this.audioPlayerService.setupWordElements(this.wordTimings, this.chatMessageElement);
    this.audioPlayerService.setupPlayer(audioUrl, audioBtn, playbackRate);
    this.player = this.audioPlayerService.player;
    this.setupSync();
    this.player.play();
    return this.player;
  }

  addMessage(messageData) {
    this.messageData = messageData;
    this.fullMessage = messageData['full_message'];
    this.fullMessageReceived = true;
    this.emitMessageAdded(this.messageData['chat_message_id']);
    this.checkMessageReady();
  }

  showMessage() {
    this.chatElement.insertAdjacentHTML('beforeend', this.messageData['data']);
    this.chatMessageElement = this.chatElement.querySelector(`#chat-message-${this.messageData['chat_message_id']}`);
    this.emitMessageShown(this.messageData['chat_message_id']);
  }

  checkMessageReady() {
    if (this.textOnlyMessageReady() || this.audioMessageReady()) {
      this.emitMessageReady(this.messageData['chat_message_id']);
      this.allAudioReceived = false;
    }
  }

  textOnlyMessageReady() {
    return this.textOnly && this.fullMessageReceived;
  }

  audioMessageReady() {
    return this.allAudioReceived && this.fullMessageReceived;
  }

  updateAudioReceived() {
    this.allAudioReceived = true;
    this.checkMessageReady();
  }

  emitMessageReady(messageId) {
    const event = new CustomEvent('chat:messageready', { detail: { messageId: messageId } });
    document.dispatchEvent(event);
  }

  emitMessageAdded(messageId) {
    const event = new CustomEvent('chat:messageadded', { detail: { messageId: messageId } });
    document.dispatchEvent(event);
  }

  emitMessageShown(messageId) {
    const event = new CustomEvent('chat:messageshown', { detail: { messageId: messageId } });
    document.dispatchEvent(event);
  }

  showTranslation(messageData) {
    if (this.chatMessageElement === undefined || this.chatMessageElement === null) {
      console.log('chatMessageElement not found');
      return;
    }

    const chatMessageElement = this.chatMessageElement.querySelector(`#chat-message-${messageData['id']}`);
    const chatMessageContentEl = chatMessageElement.querySelector('.chat-message-content');

    if (chatMessageContentEl === undefined || chatMessageContentEl === null) {
      console.log('chatMessageContentEl not found');
      return;
    }

    chatMessageContentEl.insertAdjacentHTML('beforeend', messageData['data']);
    console.log('finish showing translation')
  }

}

export default ChatMessageService;
