class CommonWordService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
    this.language;
    this.words = [];
    this.flashcardData = [];
  }

  fetchCommonWords(offset, interval, language) {
    const that = this;
    return fetch(`/langua/common_words/fetch_batch?offset=${offset}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => {
      that.updateModalContent(data, offset, interval, language);
    });
  }

  updateModalContent(data, offset, interval, language) {
    document.getElementById('batchModalLabel').innerHTML = `Words: ${interval}`;
    document.getElementById('modalBody').innerHTML = this.buildModalBody(data, offset, language);
    const rangeStart = (parseInt(offset) - 1) * 100 + 1;
    const rangeEnd = parseInt(offset) * 100;
    const tag = `words ${rangeStart}-${rangeEnd}`;

    this.language = language;
    this.words = data;
    this.flashcardData = data.map(item => ({
      front: item.word,
      back: item.translation,
      tags: [tag]
    }));

    $('#batchDetailsModal').modal('show');
  }

  buildModalBody(data, offset) {
    let modalBodyHtml = '';

    if (data.length > 0) {
      if (data.every(item => item.user_has_flashcard === true)) {
        modalBodyHtml += `
          <div class="d-flex justify-content-center">
            <a href="javascript:void(0)" class="btn btn-primary d-none" id="js-add-all-words-btn" style="font-size: 1.2rem; font-weight: 500; flex: 0 0 50%;" data-offset="${offset}">
              Add all words as flashcards
            </a>
            
            <div id="check-icon-all-words" class="alert alert-warning">
              <div class="d-flex justify-content-center">Words added.<a href="/langua/vocabulary" class="d-flex justify-content-center"> Click here to go to your flashcards.</a></div>
              <div class="d-flex justify-content-center"> Or, <a href="javascript:void(0)" class="d-flex justify-content-center" id="js-remove-all-words-btn" data-offset="${offset}"> remove all words from this pack.</a></div>
            </div>
          </div>
          `;
      } else {
        modalBodyHtml += `
          <div class="d-flex justify-content-center">
            <a href="javascript:void(0)" class="btn btn-primary" id="js-add-all-words-btn" style="font-size: 1.2rem; font-weight: 500; flex: 0 0 50%;" data-offset="${offset}">
              Add all words as flashcards
            </a>

            <div id="check-icon-all-words" class="d-none alert alert-warning">
              <div class="d-flex justify-content-center">Words added.<a href="/langua/vocabulary" class="d-flex justify-content-center"> Click here to go to your flashcards.</a></div>
              <div class="d-flex justify-content-center"> Or, <a href="javascript:void(0)" class="d-flex justify-content-center" id="js-remove-all-words-btn" data-offset="${offset}">remove all words from this pack.</a></div>
            </div>
          </div>
          `;
      }

      data.forEach((item, index) => {
        let wordButton;
        if (item.user_has_flashcard) {
          wordButton = `
            <a href="javascript:void(0)" class="btn btn-primary">
              <i id="check-icon-${index}" class="fa fa-check check-icon"></i>
              <i id="plus-icon-${index}" class="fa fa-plus plus-icon d-none" aria-hidden="true"></i>
            </a>
          `;
        } else {
          wordButton = `
              <a href="javascript:void(0)" class="btn btn-primary js-add-word-btn" data-word='${item.word}' data-translation='${item.translation}' data-index='${index}' data-offset='${offset}'>
                  <i id="plus-icon-${index}" class="fa fa-plus plus-icon" aria-hidden="true"></i>
                  <i id="check-icon-${index}" class="fa fa-check check-icon d-none" aria-hidden="true"></i>
                </a>
              `;
        }

        modalBodyHtml += `
          <div class="row m-2 pb-1">
              <div class="col-2"></div>
              <div class="col-8">
                <div class="d-flex justify-content-center pt-1">
                  ${item.word} = ${item.translation}
              </div>
              </div>
              <div class="col-2 d-flex justify-content-center">
                ${wordButton}
            </div>
            </div>
          `;
      });
    }

    return modalBodyHtml;
  }

  addBatchWords(interval_offset) {
    const that = this;
    const rangeStart = (interval_offset - 1) * 100 + 1;
    const rangeEnd = interval_offset * 100;
    const tag = `words ${rangeStart}-${rangeEnd}`;

    fetch('/langua/flashcards/batch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': that.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        flashcard: {
          flashcards: that.flashcardData,
          language: that.language,
          flashcard_type: 'word',
          tags: [tag]
        },
        batch_interval_offset: interval_offset
      })
    }).then(response => response.json())
      .then(function (data) {
        let $addAllWordsBtn = $('#js-add-all-words-btn');
        let $checkIcon = $('#check-icon-all-words');
        let $allPlusButtons = $("[id^='plus-icon-']")
        let $allCheckButtons = $("[id^='check-icon-']")

        that.applyTransitionAndTransform($addAllWordsBtn)
        that.applyTransitionAndTransform($allPlusButtons)
        that.applyTransitionAndTransform($allCheckButtons)

        that.replaceButtons($addAllWordsBtn, $checkIcon)
        that.replaceButtons($allPlusButtons, $allCheckButtons)
      });
  }

  removeBatchWords(interval_offset) {
    const that = this;

    fetch('/langua/flashcards/batch_remove', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': that.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        words: that.flashcardData.map(item => item.front),
        language: that.language,
        batch_interval_offset: interval_offset
      })
    }).then(response => response.json())
      .then(function (data) {
        let $addAllWordsBtn = $('#js-add-all-words-btn');
        let $checkIcon = $('#check-icon-all-words');
        let $allPlusButtons = $("[id^='plus-icon-']")
        let $allCheckButtons = $("[id^='check-icon-']")

        that.applyTransitionAndTransform($checkIcon)
        that.applyTransitionAndTransform($allCheckButtons)
        that.applyTransitionAndTransform($allPlusButtons)

        that.replaceButtons($checkIcon, $addAllWordsBtn)
        that.replaceButtons($allCheckButtons, $allPlusButtons)
      });
  }

  addWord(word, translation, index, interval_offset) {
    const that = this;
    const rangeStart = (interval_offset - 1) * 100 + 1;
    const rangeEnd = interval_offset * 100;
    const tag = `words ${rangeStart}-${rangeEnd}`;

    fetch('/langua/flashcards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': that.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        flashcard: {
          front: word,
          back: translation,
          language: that.language,
          tags: [tag],
          flashcard_type: 'word'
        }
      })
    }).then(response => response.json())
      .then(function (data) {
        let $plusIcon = $('#plus-icon-' + index);
        let $checkIcon = $('#check-icon-' + index);

        that.applyTransitionAndTransform($plusIcon)
        that.replaceButtons($plusIcon, $checkIcon)
      });
  }

  applyTransitionAndTransform($element) {
    $element.css({
      transition: 'transform 0.3s ease-in-out',
      transform: 'rotate(45deg) scale(0)'
    });
  }

  replaceButtons($initialElement, $finalElement) {
    setTimeout(() => {
      // remove initial element from DOM
      $initialElement.addClass('d-none');

      // add final element to DOM
      $finalElement.removeClass('d-none');

      setTimeout(() => {
        $finalElement.css({
          transform  : 'rotate(0deg) scale(1)'
        });
      }, 50);
    }, 300);
  }
}

module.exports = CommonWordService;
