require("bootstrap-daterangepicker/daterangepicker.css");

import 'bootstrap-daterangepicker';

function enableDateFields($scope) {
  function clearDate(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $dateField = $(event.target).closest('.date-input').find('input');

    // clear the cloudinary id.
    $dateField.val(null);

  }

  var singleDateRange = $scope.find('input.single-daterange');
  if (singleDateRange.length > 0) {
    import(/* webpackChunkName: "moment" */ 'moment').then((momentModule) => {

      const moment = momentModule.default;

      singleDateRange.daterangepicker({
        singleDatePicker: true,
        autoUpdateInput: false,
        locale: {
          cancelLabel: 'Clear'
        },
        showDropdowns: true,
        minYear: 1901,
        maxYear: parseInt(moment().format('YYYY'),10),
        startDate: '01/01/2000'
      });
    });
  }

  $scope.find('input.single-daterange').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY'));
  });

  $scope.find('input.single-daterange').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $scope.find(".date-input button.clear").click(clearDate);
}

$(document).on('turbolinks:load', function() {
  enableDateFields($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableDateFields($(event.target));
})
