import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function () {
  var contentSearchFilter = document.getElementById('content-filter');

  if (contentSearchFilter) {
    let hideViewedBtn = document.getElementById('js-toggle-hide-viewed');
    let showViewedBtn = document.getElementById('js-toggle-show-viewed');
    let userFiltersInput = document.getElementById('user_filters');

    var resetContentSearchForm = function () {
      $('.js-filter-dialects').val(undefined);
      $('.js-language-select').val(undefined);
      $('.js-filter-levels').val(undefined);
      $('.js-filter-content-type').val(undefined);
      $('.js-filter-tags').val(undefined);
      $('.js-filter-duration').val(undefined);
      $('.js-filter-selectpicker').val(undefined);
      if (hideViewedBtn) {
        hideViewedBtn.checked = false;
      }
      if (showViewedBtn) {
        showViewedBtn.checked = false;
      }

      if (userFiltersInput) {
        updateUserFilters(true);
      }

      $(contentSearchFilter).submit();
    }

    var submitContentSearchForm = function (refreshPage) {
      if (userFiltersInput) {
        updateUserFilters(true);
      }

      var $form = $(contentSearchFilter);
      var formAction = $form.attr('action');

      let formData = $form.serialize();

      if (refreshPage) {
        $form.submit();
      } else {
        Rails.fire(contentSearchFilter, 'submit');
        var url = formAction + "?" + formData;

        // scroll to top of page if scroll position is past first page
        if (window.pageYOffset > window.innerHeight) {
          window.scrollTo(0, 0);
        }

        history.replaceState(history.state, "", formAction + "?" + formData);
      }
    }

    $('.js-filter-dialects').on('change', function (evt) {
      submitContentSearchForm(true)
    })
    $('.js-language-select').on('change', function (evt) {
      $('.js-filter-dialects').val(undefined);
      $('.js-filter-levels').val(undefined);
      $('.js-filter-content-type').val(undefined);
      $('.js-filter-tags').val(undefined);
      $('.js-filter-duration').val(undefined);
      $('.js-filter-selectpicker').val(undefined);
      submitContentSearchForm(true)
    })
    $('.js-filter-levels').on('change', function (evt) {
      submitContentSearchForm(true)
    })
    $('.js-filter-content-type').on('change', function (evt) {
      if (evt.target.value === 'audio') {
        window.location.href = '/langua/podcasts'
      } else {
        submitContentSearchForm(true)
      }
    })
    $('.js-filter-tags').on('change', function (evt) {
      submitContentSearchForm(true)
    })
    $('.js-filter-duration').on('change', function (evt) {
      submitContentSearchForm(true)
    })
    $('.js-filter-selectpicker').on('change', function (evt) {
      submitContentSearchForm(true)
    })
    if (hideViewedBtn) {
      hideViewedBtn.addEventListener('change', function (evt) {
        if (this.checked) { showViewedBtn.checked = false; }

        submitContentSearchForm(true)
      })
    }
    if (showViewedBtn) {
      showViewedBtn.addEventListener('change', function (evt) {
        if (this.checked) { hideViewedBtn.checked = false; }

        submitContentSearchForm(true)
      })
    }

    $('#js-reset-form').on('click', function (e) {
      e.stopPropagation(false);
      resetContentSearchForm();
    });

    // if user not logged in display sign up modal after 20 seconds
    let signupLink = document.querySelector('a[data-target="#signup-modal"]');
    let signupModal = document.getElementById('signup-modal');

    if (signupLink) {
      let timerId = setTimeout(function () {
        signupLink.click();
      }, 20000);

      $(signupModal).on('shown.bs.modal', function () {
        clearTimeout(timerId);
      });
    }

    function updateUserFilters(value = true) {
      userFiltersInput.value = value;
    }
  }
});
