class TranslationService {
  constructor(translationProvider, language, includeContextSentence, csrfToken) {
    this.translationProvider = translationProvider;
    this.language = language;
    this.includeContextSentence = includeContextSentence;
    this.csrfToken = csrfToken;
  }

  setTranslationProvider(translationProvider) {
    this.translationProvider = translationProvider;
  }

  setIncludeContextSentence(includeContextSentence) {
    this.includeContextSentence = includeContextSentence;
  }

  // Fetches translation from the server
  fetchTranslation(wordText, wordInfo, sentenceText, language, translationProvider, callback) {
    fetch('/langua/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        word: wordText,
        word_info: wordInfo,
        context_sentence: this.includeContextSentence ? sentenceText : null,
        language: language,
        translation_type: 'word',
        translation_provider: translationProvider,
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.translation) {
        // Call the callback function to handle UI updates
        data.context_sentence = sentenceText;
      }

      callback(data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  // Translate a word and update UI
  translateWord(wordElement, currentSentence, callback) {
    const wordText = wordElement.innerText.trim();
    const sentenceText = currentSentence.trim(); // Assuming currentSentence is globally available
    const wordInfo = wordElement.dataset.wordInfo ? JSON.parse(wordElement.dataset.wordInfo) : null;

    if (wordElement.classList.contains('loading')) {
      return;
    }

    wordElement.classList.add('loading');
    // Fetch translation and update UI via callback
    this.fetchTranslation(wordText, wordInfo, sentenceText, this.language, this.translationProvider, callback);
  }

  // Translate a word and update UI
  translateSentence(currentSentence, callback) {
    const sentenceText = currentSentence.trim(); // Assuming currentSentence is globally available

    // Fetch translation and update UI via callback
    fetch('/langua/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        sentence: sentenceText,
        language: this.language,
        translation_type: 'sentence',
        translation_provider: this.translationProvider
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      callback(data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  // Translate a word and update UI
  translateMultiple(word, callback) {

    // Fetch translation and update UI via callback
    fetch('/langua/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        word: word,
        language: this.language,
        translation_type: 'multiple',
        translation_provider: 'gpt'
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      callback(data);
    })
  }
}

export default TranslationService;
