import $ from 'jquery';

function enableSelects($scope) {
  var selectEls = $scope.find('.select2');
  if (selectEls.length > 0) {
    import(/* webpackChunkName: "select2" */ 'select2').then(() => {
      selectEls.each(function(index, select) {
        var $select = $(select);
        var options = {};

        if ($select.hasClass('disable-search')) {
          options.minimumResultsForSearch = -1;
        }

        options.tags = $select.hasClass('accepts-new');

        // https://stackoverflow.com/questions/29290389/select2-add-image-icon-to-option-dynamically
        function formatState (opt) {
          var imageUrl = $(opt.element).attr('data-image');
          var imageHtml = "";
          if (imageUrl) {
            imageHtml = '<img src="' + imageUrl + '" /> ';
          }
          return $('<span>' + imageHtml + opt.text + '</span>');
        };

        options.templateResult = formatState;
        options.templateSelection = formatState;

        $select.select2(options);
      });
    });
  }
}

window.enableSelects = enableSelects;

$(document).on('turbolinks:load', function() {
  enableSelects($('body'));

  $('#calendar-tab').on('shown.bs.modal', function (e) {
    enableSelects($(e.target));
  })
})

$(document).on('sprinkles:update', function(event) {
  enableSelects($(event.target));
})
