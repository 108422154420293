// 🚫 DEFAULT RAILS INCLUDES
// This section represents the default includes for a Rails 6.0.0-rc1 application. Bullet Train's includes and your own
// includes should be specified at the end of the file, not in this section. This helps avoid merge conflicts in the
// future should the framework defaults change.

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default settings for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.

global.$ = require('jquery')
//require("tether")
//require("popper.js")
// require("slick-carousel")

import "@fontsource/rubik/300.css"
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/500.css"

// These are the packages Light Admin depends on. We recommend only enabling the ones you need as you need them.
// You'll also need to do an `yarn install {library}` before these work.

// require("chart.js")
// require("ckeditor")
// require("datatables.net")
// require("datatables.net-bs")
// require("dropzone") - dynamically loading now
// require("editable-table")
// require("ion-rangeslider") - dynamically loading now
// require("jquery-bar-rating")
// global.moment = require("moment") - dynamically loading now
// global.momentRange = require("moment-range") - dynamically loading now
// global.momentTimezone = require("moment-timezone") - dynamically loading now
// require("perfect-scrollbar")
// require("fullcalendar")
const bootstrap = window.bootstrap = require('bootstrap');
require("bootstrap-validator")
require("bootstrap-select")

// // For some of the libraries above, you also want to include their CSS.
//require("slick-carousel/slick/slick.scss")
// require("perfect-scrollbar/dist/css/perfect-scrollbar.min.css")
//require("fullcalendar/main.min.css")
// require("datatables.net-bs/css/dataTables.bootstrap.min.css")
require("dropzone/dist/dropzone.css")
// require('ion-rangeslider/css/ion.rangeSlider.css') - dynamically loading now
//require("bootstrap-select/dist/css/bootstrap-select.min.css")
import('select2/dist/css/select2.min')

// require("editable-table")
// require("ion-rangeslider")
// require("jquery-bar-rating")
// require("moment")

// require("datatables.net")
// require("datatables.net-bs")
// require("datatables.net-bs/css/dataTables.bootstrap.min.css")

// require("dropzone")
// require("dropzone/dist/dropzone")

// require("fullcalendar")
// require("fullcalendar/dist/fullcalendar.min.css")

// require("perfect-scrollbar")
// require("perfect-scrollbar/dist/css/perfect-scrollbar.min.css")

require("slick-carousel")
require("slick-carousel/slick/slick.scss")

// Custom JavaScript for Bullet Train
require("../index")

// For inline use in `app/views/account/onboarding/user_details/edit.html.erb`.
global.jstz = require("jstz");

require('../pagy.js')

// global.cashify = require('cashify') - dynamically loading now

// global.Clipboard = require('clipboard') - dynamically loading now

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.


import "@fontsource/rubik/300.css"
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/500.css"
