document.addEventListener("turbolinks:load", function () {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  let sourceText = $('#sourceText');
  let translatedText = $('#translatedText');
  let $flashcardBtn = $('#add-flashcard-btn');
  let $plusIcon = $flashcardBtn.find('.plus-icon');
  let $checkIcon = $flashcardBtn.find('.check-icon');

  const translateSentence = (sentence, callback) => {
    fetch('/langua/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        sentence: sentence.trim(),
        language: $('#sourceLanguage').text().toLowerCase(),
        to_language: $('#targetLanguage').text().toLowerCase(),
        translation_type: 'sentence',
      }),
    })
      .then(response => response.json())
      .then(data => callback(data))
      .catch(error => console.error('Error:', error));
  };

  const updateTranslation = () => {
    const text = sourceText.val();
    if (text) {
      $('.translator-loading').show();

      // Wait for the transition to finish, then display the plus icon
      setTimeout(function () {
        $checkIcon.hide();

        $plusIcon.removeClass('d-none');
        $plusIcon.css({
          transition: 'transform 0.3s ease-in-out',
          transform: 'rotate(-45deg) scale(0)',
          display: 'inline-block'
        });

        setTimeout(function () {
          $plusIcon.css({
            transform: 'rotate(0deg) scale(1)'
          });
        }, 50);
      }, 300);

      translateSentence(text, (data) => {
        translatedText.val(data.translation);
        $('.translator-loading').hide();
      });
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const debouncedUpdateTranslation = debounce(updateTranslation, 3000);

  sourceText.on('input', debouncedUpdateTranslation);

  $('#exchangeIcon').on('click', function () {
    const sourceLang = $('#sourceLanguage').text();
    const targetLang = $('#targetLanguage').text();
    $('#sourceLanguage').text(targetLang);
    $('#targetLanguage').text(sourceLang);

    sourceText.val('');
    translatedText.val('');
  });

  $('#sourceText').on('input', function () {
    const maxLength = 70;
    const textArea = $(this);
    const errorDiv = $('#charError');

    if (textArea.val().length === 0) {
      translatedText.val('');
    }

    if (textArea.val().length > maxLength) {
      textArea.val(textArea.val().substring(0, maxLength));
      errorDiv.removeClass('d-none');
    } else {
      errorDiv.addClass('d-none');
    }
  });


  $('#translatorModal').on('hidden.bs.modal', function () {
    $('#sourceText').val('');
    $('#translatedText').val('');
  });

  $('.translator-loading').hide();
});
