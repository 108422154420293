class TranslatorModalService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
    this.initializeElements();
    this.initializeState();
    this.setupEventListeners();
  }

  initializeElements() {
    this.translatorModalEl = $('#js-chat-translator-modal');
    this.sourceText = $('#js-chat-translator-source-text');
    this.translationTextEl = this.translatorModalEl.find('.js-translation-text-container');
    this.playAudioBtn = this.translatorModalEl.find('.js-play-translator-audio-btn');
    this.sendBtn = this.translatorModalEl.find('.js-translator-chat-submit-btn');
    this.flashcardBtn = $('#js-add-flashcard-btn');
    this.plusIcon = this.flashcardBtn.find('.js-plus-icon-translator');
    this.checkIcon = this.flashcardBtn.find('.js-check-icon-translator');
    this.sourceLanguageTitleEl = $('#js-chat-translator-source-language-text');
    this.targetLanguageTitleEl = $('#js-chat-translator-target-language-text');
    this.translatorLoading = $('.translator-loading');
  }

  initializeState() {
    this.learningLanguage = this.translatorModalEl.data('learningLanguage');
    this.sourceLanguage = this.translatorModalEl.data('sourceLanguage');
    this.targetLanguage = this.translatorModalEl.data('targetLanguage');
    this.translatorLoading.hide();
    this.updateLanguageDisplay();
  }

  setupEventListeners() {
    this.translatorModalEl.on('shown.bs.modal', () => setTimeout(() => this.sourceText.trigger('focus'), 500));
    this.translatorModalEl.on('hidden.bs.modal', () => this.clearTranslationModal());
    this.flashcardBtn.on('click', (e) => this.handleFlashcardCreation(e));

    $('#js-exchange-icon').off('click').on('click', () => this.exchangeLanguages());

    this.sourceText.off('input').on('input', this.debounce(() => {
      this.handleTextAreaInput();
      this.updateTranslation();
    }, 1500));

    $(document).on('click', '.js-play-translator-audio-btn', (e) => this.handleAudioPlay(e));
    $(document).on('click', '.js-translator-chat-submit-btn', (e) => this.handleChatSubmit(e));
  }

  openModal() {
    this.sourceText.val('');
    this.translationTextEl.html('');
    this.resetAudioButton();
    this.resetFlashcardIcon();
    this.translatorModalEl.modal('show');
  }

  hideModal() {
    this.translatorModalEl.modal('hide');
  }

  updateLanguageDisplay() {
    this.sourceLanguageTitleEl.text(this.sourceLanguage.toUpperCase());
    this.targetLanguageTitleEl.text(this.targetLanguage.toUpperCase());
  }

  handleFlashcardCreation(e) {
    const sourceText = this.sourceText.val();
    const translatedText = this.translationTextEl.data('translated-text');

    if (sourceText && translatedText) {
      const [flashcardFront, flashcardBack] = this.sourceLanguage === this.learningLanguage
        ? [sourceText, translatedText]
        : [translatedText, sourceText];

      const flashcardType = flashcardFront.split(/\s+/).length >= 4 ? 'sentence' : 'word';
      this.submitFlashcard([{ front: flashcardFront, back: flashcardBack }], this.learningLanguage, flashcardType);
    }
  }

  submitFlashcard(flashcardData, language, type) {
    fetch('/langua/flashcards/batch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        flashcard: {
          flashcards: flashcardData,
          language: language,
          flashcardable_id: null,
          flashcardable_type: null,
          flashcard_type: type
        }
      })
    })
      .then(response => response.json())
      .then(() => this.animateFlashcardIcon());
  }

  animateFlashcardIcon() {
    this.plusIcon.css({ transition: 'transform 0.3s ease-in-out', transform: 'rotate(45deg) scale(0)' });
    setTimeout(() => {
      this.plusIcon.hide();
      this.checkIcon.removeClass('d-none').css({ transition: 'transform 0.3s ease-in-out', transform: 'rotate(-45deg) scale(0)', display: 'inline-block' });
      setTimeout(() => this.checkIcon.css({ transform: 'rotate(0deg) scale(1)' }), 50);
    }, 300);
  }

  exchangeLanguages() {
    [this.sourceLanguage, this.targetLanguage] = [this.targetLanguage, this.sourceLanguage];
    this.updateLanguageDisplay();
    this.sourceText.val('');
    this.translationTextEl.html('');

    this.resetAudioButton();
    this.resetFlashcardIcon();

    this.translatorModalEl.data('sourceLanguage', this.sourceLanguage);
    this.translatorModalEl.data('targetLanguage', this.targetLanguage);

    this.playAudioBtn.attr('data-language', this.targetLanguage);
    this.sendBtn.attr('data-language', this.targetLanguage);
  }

  handleTextAreaInput() {
    const maxLength = 70;
    const currentLength = this.sourceText.val().length;
    const errorDiv = $('#charError');

    if (currentLength === 0) {
      this.translationTextEl.html('');
    }
    if (currentLength > maxLength) {
      this.sourceText.val(this.sourceText.val().substring(0, maxLength));
      errorDiv.removeClass('d-none');
    } else {
      errorDiv.addClass('d-none');
    }
  }

  clearTranslationModal() {
    this.sourceText.val('');
    this.translationTextEl.html('');
    this.resetAudioButton();
    this.resetFlashcardIcon();
  }

  resetAudioButton() {
    this.playAudioBtn.removeAttr('data-audio-url');
    this.playAudioBtn = this.playAudioBtn.clone().replaceAll(this.playAudioBtn);
  }

  updateTranslation() {
    const text = this.sourceText.val();
    if (text) {
      this.translatorLoading.show();
      this.resetFlashcardIcon();
      this.resetAudioButton();

      this.translateSentence(text, (data) => {
        const translation = data.translation;
        const escapedTranslation = translation.replace(/"/g, '\\"').replace(/'/g, '&#39;');
        const translatableTranslation = translation.replace(/(\S+)/g, `<span class="js-translatable-text js-audio-word word" style="cursor: pointer;" data-translated-text='${escapedTranslation}' data-language="${this.targetLanguage}">$1</span>`);

        this.translationTextEl.replaceWith(`<div class="word-container js-translated-modal-text" data-translated-text="${escapedTranslation}">${translatableTranslation}</div>`);
        this.translationTextEl = this.translatorModalEl.find('.js-translated-modal-text');

        const audioText = this.targetLanguage == this.learningLanguage ? translation : text;
        this.playAudioBtn.attr('data-text', audioText);
        this.sendBtn.attr('data-text', audioText);

        this.translatorLoading.hide();
      });
    } else {
      this.translationTextEl.html('');
    }
  }

  translateSentence(sentence, callback) {
    fetch('/langua/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({
        sentence: sentence.trim(),
        language: this.sourceLanguage,
        to_language: this.targetLanguage,
        translation_type: 'sentence',
      }),
    })
      .then(response => response.json())
      .then(callback)
      .catch(error => console.error('Error:', error));
  }

  resetFlashcardIcon() {
    this.checkIcon.addClass('d-none');
    this.plusIcon.removeClass('d-none')
      .css({
        transition: 'none',
        transform: 'rotate(0deg) scale(1)',
        display: 'inline-block'
      });
  }

  handleAudioPlay(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.handled !== true) {
      e.handled = true;
      const text = $(e.currentTarget).data('text');
      if (text) {
        const wordEl = this.translatorModalEl.find('.word-container')[0];
        const audioOptions = { 
          voice: this.chatService.chatOptions.voice,
          voice_provider: this.chatService.chatOptions.voiceProvider,
          speed: this.chatService.chatOptions.voiceSpeed
        };
        const soundSyncEnabled = this.targetLanguage === this.learningLanguage;
        this.audioPlayerService.setupAudio(wordEl, e.currentTarget, text, this.learningLanguage, audioOptions, soundSyncEnabled);
      }
    }
  }

  handleChatSubmit(e) {
    e.preventDefault();
    const sourceText = this.sourceText.val();
    const translatedText = this.translationTextEl.data('translated-text');

    if (sourceText && translatedText) {
      const text = this.sourceLanguage === this.learningLanguage ? sourceText : translatedText;
      this.chatService.chatInput.value = this.chatService.chatInput.value + ' ' + text;
      this.hideModal();
    }
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}

export default TranslatorModalService;
