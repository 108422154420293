class NotificationService {
  constructor({ message = "Notification Text", notificationType = 'info' } = {}) {
    this.message = message;
    this.notificationType = notificationType;

    if (this.notificationType == 'info') {
      this.timeout = 5000;
    } else if (this.notificationType == 'warning') {
      this.timeout = 30000;
    } else if (this.notificationType == 'error') {
      this.timeout = 60000;
    }
    this.notificationElement = null;
  }

  show() {
    // Create the notification element
    this.notificationElement = document.createElement('div');
    this.notificationElement.className = 'notification-service-div';
    this.notificationElement.innerHTML = `<strong>${this.message}</strong>`;

    if (this.notificationType == 'info') {
      this.notificationElement.style.backgroundColor = "#00448f";
      this.notificationElement.style.color = "white";
    } else if (this.notificationType == 'warning') {
      this.notificationElement.style.backgroundColor = "yellow";
    } else if (this.notificationType == 'error') {
      this.notificationElement.style.backgroundColor = "red";
      this.notificationElement.style.color = "white";
    }

    // Create the close button
    const closeButton = document.createElement('span');
    closeButton.textContent = '×';
    closeButton.style.marginLeft = '10px';
    closeButton.style.cursor = 'pointer';
    closeButton.addEventListener('click', () => this.remove());

    // Append the close button to the notification
    this.notificationElement.appendChild(closeButton);

    // Append the notification to the body
    document.body.appendChild(this.notificationElement);

    // Remove the notification after the specified timeout
    setTimeout(() => {
      this.remove();
    }, this.timeout);
  }

  remove() {
    if (this.notificationElement && this.timeout) {
      this.notificationElement.remove();
      this.notificationElement = null;
    }
  }
}

export default NotificationService;
