document.addEventListener("turbolinks:load", function () {
    let similarWordsData = {};
    const flashcardWordElement = document.getElementById('flashcard_front_word');
    const currentWord = flashcardWordElement && flashcardWordElement.dataset.word;
    const flashcardLanguageElement = document.getElementById('flashcard_word_language');
    const language = flashcardLanguageElement && flashcardLanguageElement.dataset.language;

    $('body').on('click', '.js-flashcards-similar-words', function(e) {
        e.preventDefault();

        similarWords(currentWord);
    });

    $('body').on('click', '.js-flashcard-similar-words-btn', function(e) {
        e.preventDefault();

        if (flashcardWordElement !== null) {
            let flashcardID = flashcardWordElement.dataset.flashcardId
            let flashcardType = 'word';
            let similarWords = Object.values(similarWordsData[currentWord].result.similar_words);
            let similarWord = similarWords[$(this).data('index')];

            let flashcardData = [{
                front: similarWord.source,
                back: similarWord.translation
            }];

            if (flashcardData.length > 0) {
                batchCreateFlashcards(this, flashcardData, flashcardType, language, flashcardID, 'Flashcard');
            }
        }
    });

    function similarWords(word) {
        if (similarWordsData[word]) {
            displayResult(language, similarWordsData[word]);

            return;
        }

        $(".js-similar-words-card")
            .html(`<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>`);

        expandCardHeight(25)

        fetch('/langua/similar_words', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            credentials: 'same-origin',
            body: JSON.stringify({ word: word, language: language })
        }).then(response => response.json())
            .then(function (data) {
                if (data.error) {
                    $('.js-similar-words-card')
                        .html('<span class="d-flex justify-content-center">There was an error finding similar words. Please try again later.</span>');
                    console.log(data.error)
                } else {
                    if (Object.keys(data.result).length > 0) {
                        similarWordsData[word] = data;

                        displayResult(language, data.result.similar_words, "similar words", ".js-similar-words-card");
                    } else {
                        $(".js-similar-words-card")
                            .html(`<span class="d-flex justify-content-center">No similar words found.</span>`);
                    }
                }
            });
    }

    const { displayResult, expandCardHeight } = require('./display_result');

    let batchCreateFlashcards = require('./../contents/batch_create_flashcards');
});
