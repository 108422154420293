class AlternativeResponseService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  fetch(chatId, chatMessageId, text, language, context = null, clientProvider, model) {
    return fetch('/langua/alternative_response', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({ chat_id: chatId, chat_message_id: chatMessageId, text: text, language: language, context: context, client_provider: clientProvider, model: model })
    })
    .then(response => response.json())
  }
}

export default AlternativeResponseService;
