class AudioPlayer {
    constructor() {
      this.audioContext = new AudioContext();
      this.bufferQueue = [];
      this.isPlaying = false;
    }

    playAudioChunks(chunks) {
      // Add the chunks to the buffer queue
      this.bufferQueue.push(...chunks);

      // If not currently playing, start playing the audio
      if (!this.isPlaying) {
        this.isPlaying = true;
        this.playNextChunk();
      }
    }

    playNextChunk() {
      if (this.bufferQueue.length > 0) {
        const buffer = this.bufferQueue.shift();
        const source = this.audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(this.audioContext.destination);
        source.onended = () => {
          this.playNextChunk();
        };
        source.start(0);
      } else {
        this.isPlaying = false;
      }
    }
  }

export default AudioPlayer;
