import phraseSelectionAlert from "./phrase_selection_alert";

class Transcript {
  constructor(transcriptContainer, transcriptType, url, highlightWords = [], isMobileDevice, playerService, popoverService, translationService, learnedWords = [], savedWords = []) {
    this.transcriptContainer = transcriptContainer;
    this.transcriptType = transcriptType;
    this.url = url;
    this.jsonData = null;
    this.eventHandlers = {};
    this.highlightWords = highlightWords;
    this.learnedWords = learnedWords;
    this.savedWords = savedWords;
    this.isMobileDevice = isMobileDevice;

    this.playerService = playerService;
    this.popoverService = popoverService;
    this.translationService = translationService;
    this.isSelecting = false;
    this.isDragging = false;
  }

  // Register an event handler
  on(event, handler) {
    if (!this.eventHandlers[event]) {
      this.eventHandlers[event] = [];
    }
    this.eventHandlers[event].push(handler);
  }

  // Trigger an event
  trigger(event, ...args) {
    if (this.eventHandlers[event]) {
      this.eventHandlers[event].forEach(handler => handler(...args));
    }
  }

  async setup() {
    try {
      if (this.transcriptType === 'json') {
        await this.fetchAndDisplayJSON();
      } else if (this.transcriptType === 'vtt') {
        await this.fetchAndDisplayVTT();
      }
      // Trigger the ready event
      this.trigger('ready');
    } catch (error) {
      console.error('Error in setup:', error);
    }
  }

  setupEventListeners() {
    this.transcriptContainer.addEventListener('scroll', function () {
      const activeTranslatedWord = document.getElementsByClassName('active')[0]
      if (activeTranslatedWord && this.isElementOutOfView(activeTranslatedWord)) {
        $(`#${activeTranslatedWord.id}`).popover('hide')
      } else if (activeTranslatedWord && !this.isElementOutOfView(activeTranslatedWord)) {
        $(`#${activeTranslatedWord.id}`).popover('show')
      }
    })
  }

  async fetchAndDisplayJSON() {
    try {

      // load skeleton on vtt container
      this.transcriptContainer.innerHTML = `
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
`;

      const response = await fetch(this.url);
      const content = await response.text();

      this.transcriptContainer.innerHTML = '';

      // fix for json.parse error
      this.jsonData = JSON.parse(content.replace(/\bNaN\b/g, "null"));

      this.displaySegments();
    } catch (error) {
      console.error('Error fetching JSON file:', error);
    }
  }

  // display vtt file
  async fetchAndDisplayVTT() {
    try {

      // load skeleton on vtt container
      this.transcriptContainer.innerHTML = `
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
<div class="skeleton skeleton-text mt-2"></div> <div class="skeleton skeleton-text"></div>
`;

      import(/* webpackChunkName: "webvtt-parser" */ 'webvtt-parser').then((webvttModule) => {
        fetch(this.url)
          .then(response => response.text())
          .then(data => {
            const parser = new webvttModule.WebVTTParser()
            const cues = parser.parse(data).cues

            this.transcriptContainer.innerHTML = '';

            cues.forEach((cue, index) => {
              const segmentDiv = this.displaySegment(cue, index);

              let splitCue = cue.text.split(' ')
              splitCue.forEach((word, wordIndex) => {
                this.displayWord({
                  text: word,
                  start: cue.startTime,
                  end: cue.endTime
                }, wordIndex, index, segmentDiv);
              });

              this.transcriptContainer.appendChild(segmentDiv);
            })

            setupAudioSync();
          })
      })
    } catch (error) {
      console.error('Error fetching VTT file:', error);
    }
  }

  displaySegments() {
    if (this.jsonData.results) {
      this.jsonData.results.channels[0].alternatives[0].paragraphs.paragraphs.forEach((paragraph, index) => {
        paragraph.sentences.forEach((segment, segmentIndex) => {
          const segmentDiv = this.displaySegment(segment, segmentIndex);

          segment.words.forEach((word, wordIndex) => {
            // if word start time is the same as the segment end time, skip
            if (word.start === segment.end) {
              return;
            }

            let wordInfo = '';
            if (segment.analysis) {
              let trimmedWord = word.text.trim().replace(/[.,\/#!$%\^&\*;:{}=\-_~()¡¿]/g, "");
              wordInfo = segment.analysis.words[trimmedWord];
            }
            this.displayWord(word, wordIndex, segmentIndex, segmentDiv, wordInfo);
          });

          this.transcriptContainer.appendChild(segmentDiv);
        });

        this.transcriptContainer.appendChild(document.createElement('br'));
        this.transcriptContainer.appendChild(document.createElement('br'));
      });
    } else {
      this.jsonData.segments.forEach((segment, segmentIndex) => {
        const segmentDiv = this.displaySegment(segment, segmentIndex);

        segment.words.forEach((word, wordIndex) => {
          this.displayWord(word, wordIndex, segmentIndex, segmentDiv);
        });

        this.transcriptContainer.appendChild(segmentDiv);
      });
    }
  }

  displayWord(word, wordIndex, segmentIndex, segmentDiv, wordInfo = '') {
    const wordSpan = document.createElement('span');
    wordSpan.textContent = `${word.punctuated_word || word.text} `;
    wordSpan.className = 'word';
    wordSpan.dataset.startTime = word.start;
    wordSpan.dataset.endTime = word.end;
    wordSpan.dataset.wordInfo = JSON.stringify(wordInfo);
    wordSpan.id = `word-${segmentIndex}-${wordIndex}`;

    if (this.highlightWords.includes(word.word)) {
      wordSpan.classList.add('highlighted-word');
    }

    if (this.learnedWords.includes(word.word)) {
      wordSpan.classList.add('learned-word');
    }

    if (this.savedWords.includes(word.word)) {
      wordSpan.classList.add('saved-word');
    }

    wordSpan.addEventListener('click', (e) => {
      if (!wordSpan.classList.contains('challenge')) {
        e.stopPropagation();

        this.trigger('wordClick', wordSpan);
      }
    });

    wordSpan.addEventListener('mousedown', (e) => {
      this.handlePhraseSelectStart()
    });

    wordSpan.addEventListener('mousemove', (e) => {
      this.handlePhraseSelect()
    });

    wordSpan.addEventListener('mouseup', (e) => {
      this.handlePhraseSelectEnd(e)
    });

    segmentDiv.appendChild(wordSpan);
  }

  handlePhraseSelectStart() {
    this.isSelecting = true;

    document.querySelectorAll('.phrase-end').forEach(word => {
      word.classList.remove('phrase-end');
    });
  }

  handlePhraseSelect() {
    if (this.isSelecting) {
      this.isDragging = true;
    }
  }

  handlePhraseSelectEnd(e) {
    if (this.isDragging) {
      e.target.classList.add('phrase-end');

      this.isSelecting = false;
      this.isDragging = false;

      e.stopPropagation();

      let selectedTextLength = window.getSelection().toString().replace(/\s+/g, '').length;

      if (selectedTextLength > 0 && selectedTextLength <= 70) {
        this.trigger('phraseSelected');
      } else {
        if (selectedTextLength > 70) {
          let phraseSelectionAlert = require('./phrase_selection_alert');

          phraseSelectionAlert();
        }
      }
    } else {
      this.isSelecting = false;
      this.isDragging = false;
    }
  }

  displaySegment(segment, segmentIndex) {
    const segmentDiv = document.createElement('span');
    segmentDiv.id = `segment-${segmentIndex + 1}`;
    segmentDiv.dataset.id = `${segment.id}`;
    segmentDiv.classList.add('unactive-sentence')

    if (segment.text) {
      segmentDiv.dataset.text = segment.text;
      segmentDiv.dataset.startTime = segment.start || segment.startTime;
      segmentDiv.dataset.endTime = segment.end || segment.endTime;
    }

    segmentDiv.addEventListener('click', () => {
      this.trigger('segmentClick', segmentDiv);
    })

    return segmentDiv;
  }

  isElementOutOfView(element) {
    const containerRect = this.transcriptContainer.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();
    let scrollThreshold;

    if (this.isMobileDevice) {
      scrollThreshold = 250;
    } else {
      scrollThreshold = 150;
    }

    if (
      elementRect.bottom < containerRect.top ||
      elementRect.top > (containerRect.bottom - scrollThreshold) ||
      elementRect.right < containerRect.left ||
      elementRect.left > containerRect.right
    ) {
      return true;
    }

    return false;
  }

  setActiveElement(element, translateEnabled = true, phraseSelected = false) {
    if (phraseSelected) {
      element = document.querySelector('.phrase-end')
    }

    const currentActive = document.getElementsByClassName('active');
    const hasCurrentActive = currentActive.length > 0;
    const isElementCurrentActive = hasCurrentActive && element === currentActive[0];
    const elementStartTime = element.dataset.startTime;

    if (isElementCurrentActive) {
      this.popoverService.disposePopover(currentActive[0]);
      this.playerService.play();
      return;
    }

    if (hasCurrentActive) {
      this.popoverService.disposePopover(currentActive[0]);
      this.playerService.play();
    }

    if (elementStartTime) {
      this.playerService.pause();
      this.playerService.setCurrentTime(elementStartTime);
    }

    if (element.classList.contains('unactive-sentence') || element.classList.contains('active-sentence')) {
      const activeSentence = document.querySelector('.active-sentence');
      if (activeSentence) {
        activeSentence.classList.replace('active-sentence', 'unactive-sentence');
      }
      element.classList.add('active-sentence');
    } else {
      let currentSentenceEl = element.parentNode;
      let currentSentence;

      if (currentSentenceEl.dataset.text !== undefined) {
        currentSentence = currentSentenceEl.dataset.text;
      } else {
        currentSentence = Array.from(currentSentenceEl.childNodes)
          .filter(word => word.classList.contains('word'))
          .map(word => word.textContent)
          .join(' ');
      }

      if (translateEnabled) {
        this.popoverService.showLoadingPopover(element);

        if(phraseSelected) {
          let currentPhrase = window.getSelection().toString();

          this.translationService.translateSentence(currentPhrase, (translatedData) => {
            // Callback function to execute after translation is done

            // Show the loading popover
            this.popoverService.updatePopoverContent(element, translatedData, true);
            element.classList.remove('loading');
          });
        } else {
          this.translationService.translateWord(element, currentSentence, (translatedData) => {
            // Callback function to execute after translation is done

            // Show the loading popover
            this.popoverService.updatePopoverContent(element, translatedData);
            element.classList.remove('loading');
          });
        }
      } else {
        this.playerService.togglePlayer();
      }
    }
  }
}

export default Transcript;
