document.addEventListener("turbolinks:load", function () {
  if (document.getElementById('flashcard_front_word')) {
    const flashcardWordElement = document.getElementById('flashcard_front_word');
    const currentWord = flashcardWordElement && flashcardWordElement.dataset.audioWord;
    const flashcardLanguageElement = document.getElementById('flashcard_word_language');
    const language = flashcardLanguageElement && flashcardLanguageElement.dataset.language;
    let soundPlaying = false;

    $('body').on('click', '.js-play-audio-btn', function(e) {
      e.preventDefault();

      playAudioWord(this, currentWord, language, soundPlaying)
    });

    let playAudioWord = require('./../contents/play_audio_word');
  }
});
