async function getOneTimeUploadUrl() {
  var url = "/account/cloudflare/upload_signatures/new";
  var data = `{
    "thumbnailTimestampPct": 0.568427
  }`;

  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }

  });

  const responseJSON = await response.json();

  return responseJSON;
}

async function saveVideo(videoUID) {
  // Grab the CSRF token from the meta tag
  const csrfToken = document.querySelector("[name='csrf-token']").content;
  var url = `/account/cloudflare/videos/${videoUID}`;

  fetch(url, {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json'
    }
  }).then(response => response.json()).then(data => {
    var el = document.getElementById("profile-video-upload")
    el.innerHTML = "<p class='alert alert-warning'>Your video is being processed. Please wait a minute then refresh the page.</p>";
  }).catch((error) => {
    console.error('Error:', error);

  });
}

async function deleteVideo(videoUID) {
  // Grab the CSRF token from the meta tag
  const csrfToken = document.querySelector("[name='csrf-token']").content;
  var url = `/account/cloudflare/videos/${videoUID}`;

  fetch(url, {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json'
    }
  }).then(response => response.json()).then(data => {
    console.log(`Video deleted ${videoUID}`);
  }).catch((error) => {
    console.error('Error:', error);
  });
}

function setupDropzone(dropzoneID) {

  var previewEl = "video#preview";
  var $previewEl = $(previewEl);
  var $previewContainerEl = $(".preview-profile-video");
  var videoUID;

  import(/* webpackChunkName: "dropzone" */ 'dropzone').then((dropzone) => {
    let { Dropzone } = dropzone;
    Dropzone.autoDiscover = false;

    let myDropzone = new Dropzone(dropzoneID, {
      url: '/upload',
      headers: {
        'Cache-Control': null,
        'X-Requested-With': null,
      },
      autoProcessQueue: false,
      maxFiles: 1,
      maxFileSize: 209715200,
      addRemoveLinks: true,
      acceptedFiles: 'video/*',
      disablePreviews: false
    });

    myDropzone.on("addedfile", file => {
      if (myDropzone.files.length > 1) {
        myDropzone.removeFile(myDropzone.files[0]);
      }

      getOneTimeUploadUrl().then(data => {
        videoUID = data.uid;
        myDropzone.options.url = data.uploadURL;

        myDropzone.processQueue();
      });
    });

    myDropzone.on("removedfile", file => {
      deleteVideo(videoUID);
      $previewContainerEl.addClass('d-none');
      if ($previewEl != undefined) {
        $previewEl[0].src = '';
      }
    });

    myDropzone.on("success", file => {
      var hiddenField = "#tutor_settings_form_team_cloudflare_video_id";
      var $cloudflareField = $('.cloudflare-field');
      var dropzoneID = "#video-dropzone";
      var $videoDropzone = $cloudflareField.find(dropzoneID)

      // set cloudflare video ID
      $(hiddenField).val(videoUID);

      // save video
      saveVideo(videoUID);

      // remove dropzone
      $videoDropzone.addClass('d-none');
      //myDropzone.destroy();
    });

  });
}

document.addEventListener('turbolinks:load', function() {
  var dropzoneID = "video-dropzone";
  var dropzoneEl = document.getElementById(dropzoneID);
  var $hiddenField = $("#tutor_settings_form_team_cloudflare_video_id");

  if (dropzoneEl) {
    setupDropzone(`#${dropzoneID}`);
  }
})

function enableCloudflareVideos($scope) {

  function clearVideoFromCloudflareField(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $cloudflareField = $(event.target).closest('.cloudflare-field');
    var $hiddenField = $cloudflareField.find("input[type='hidden']");
    var $clearButton = $cloudflareField.find("button.clear");
    var $videoPreview = $cloudflareField.find('iframe')
    var dropzoneID = "#video-dropzone";
    var $videoDropzone = $cloudflareField.find(dropzoneID)
    var previewEl = "video#preview";
    var $previewEl = $(previewEl);
    var $previewContainerEl = $(".preview-profile-video");
    var $videoThumbnailSliderContainerEl = $("#js-profile-video-thumbnail-slider-container");

    // clear the cloudflare id.
    $hiddenField.val(null);

    // remove any existing video preview
    $previewContainerEl.addClass('d-none');

    // hide the trash button
    $clearButton.addClass('d-none');

    // hide thumbnail slider
    $videoThumbnailSliderContainerEl.addClass('d-none');

    // show Dropzone
    $videoDropzone.removeClass('d-none');

  }

  $scope.find(".cloudflare-field button.clear").click(clearVideoFromCloudflareField);
};

$(document).on('turbolinks:load', function() {

  const streamPlayerEl = document.getElementById('stream-player');

  if (streamPlayerEl) {
    enableCloudflareVideos($('body'));

    const player = Stream(streamPlayerEl);

    player.addEventListener('canplay', (event) => {

      var thumbnailTimestampField = "#tutor_settings_form_team_cloudflare_video_thumbnail_timestamp";

      // Initialize thumbnail slider

      import(/* webpackChunkName: "ion-rangeslider" */ 'ion-rangeslider').then(() => {
        import(/* webpackChunkName: "ion-rangeslider-css" */ 'ion-rangeslider/css/ion.rangeSlider.css').then(() => {
          $(".js-thumbnail-timestamp-slider").ionRangeSlider({
            min: 1,
            max: Math.floor(player.duration),
            from: $(thumbnailTimestampField).val(),
            skin: 'round',
            hide_min_max: true,
            hide_from_to: true,
            onChange: function (data) {
              player.currentTime = data.from;
              player.pause();
            },
            onFinish: function (data) {
              player.currentTime = data.from;
              player.pause();
            }
          });

          // Show thumbnail slider
          $(".js-thumbnail-timestamp-container").removeClass('d-none');
        });
      })
    })
  }
})

$(document).on('sprinkles:update', function(event) {
  const streamPlayerEl = document.getElementById('stream-player');

  if (streamPlayerEl) {
    enableCloudflareVideos($(event.target));
  }
})

