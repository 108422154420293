import Rails from '@rails/ujs';

document.addEventListener("turbolinks:load", () => {
  let paginationContainer = document.querySelector('.js-pagination-container');

  if (paginationContainer == null) { return }

  function setupContentInfiniteScroll(callback = null) {
    let viewMoreBtn = document.getElementById('js-view-more-contents-btn');

    if (viewMoreBtn == null) { return }

    viewMoreBtn.addEventListener('click', function(event) {
      event.preventDefault();
      viewMoreBtn.disabled = true;
      viewMoreBtn.innerHTML = 'Loading...';
      getData();
    });

    function getData() {
      // contentsTarget - target for inject next page
      var contentsTarget = document.querySelector('.js-infinite-scroll');
      // paginationTarget - target for replace pagination
      let paginationTarget = document.querySelector('.pagination-link');
      let nextPage = document.querySelector("a[rel='next']");

      if (nextPage == null) { return }

      let url = nextPage.href;
      let currentScrollPosition = window.scrollY;

      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          contentsTarget.insertAdjacentHTML('beforeend', data.entries);
          paginationTarget.innerHTML = data.pagination;

          nextPage = document.querySelector("a[rel='next']");
          if (nextPage == null) {
            viewMoreBtn.style.display = 'none';
          } else {
            viewMoreBtn.disabled = false;
            viewMoreBtn.innerHTML = 'Click here to see more contents';
          }

          // Set the scroll position of the page to the saved value
          // Hack to prevent the page from jumping to the top
          window.scrollTo(0, currentScrollPosition);

          if (callback) {
            callback();
          }
        }
      });
    }
  }

  window.setupContentInfiniteScroll = setupContentInfiniteScroll;
  setupContentInfiniteScroll();

});
