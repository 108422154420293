class TextCorrectionService {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  fetchCorrection(chatId, chatMessageId, text, language, context = null, clientProvider, model, debug = false) {
    return fetch('/langua/text_correction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        chat_id: chatId,
        chat_message_id: chatMessageId,
        text: text,
        language: language,
        context: context,
        client_provider: clientProvider,
        model: model,
        debug: debug
      })
    })
    .then(response => response.json())
  }
}

export default TextCorrectionService;
